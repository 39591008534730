import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import dayjs from 'dayjs';

import { FaCheck } from 'react-icons/fa';
import { ImCancelCircle } from 'react-icons/im';
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import { FaArrowsRotate, FaRegFaceGrimace, FaRegFaceGrinBeamSweat, FaXmark } from 'react-icons/fa6';

import Div from 'src/components/div/Div';
import { Button } from 'src/components/Button';
import Spinner from 'src/components/spinner/Spinner';

import { InputComponent } from 'src/components/form/InputComponent';
import { Chips } from 'src/components/chips/Chips';
import { SharedItem } from 'src/components/collaborators/SharedItem';
import { Modal } from '@components/modal/Modal';
import { FileExplorer } from '@components/file-explorer/FileExplorer';

import { handleLegalOfficerAuditDecision, selectSelectedAccount } from 'src/reducers/account.reducer';

import { BUTTON_COLORS } from '@utils/constants';
import { getAuditQuestionInfosByName } from '@utils/utils';
import { fetchContentfulEntryById, fetchSubContractorResponses } from '@utils/api';
// TODO : use the component that will be created in UI lib
import ActionStatus from '@pages/ActionPlanDash/components/ActionStatus';
import useDidUpdate from '@hooks/useDidUpdate';

const Audits = () => {
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();

  const account = useSelector(selectSelectedAccount);

  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [surveyData, setSurveyData] = useState(null);
  const [subContractorData, setSubContractorData] = useState(null);
  const [showAvailableDocuments, setShowAvailableDocuments] = useState({ show: false, fileName: null });

  useEffect(() => {
    const fetchData = async () => {
      const entryId = '2LGGPkFMcjg5G9SztSFP5y';
      try {
        const result = await fetchContentfulEntryById(entryId);
        setSurveyData(result);
      } catch (err) {
        console.log("🚀 ~ err:", err)
      }
    };
    fetchData();
  }, []);

  const entities = useMemo(() => {
    return account.entities.filter(
      entity => entity?.actionPlan?.actions?.length > 0 && entity.actionPlan.actions.some(action => action.category === 'Audit')
    );
  }, [account]);

  const selectEntitiesOptions = useMemo(() => {
    return entities?.map(entity => {
      return { label: entity.name, value: entity._id };
    });
  }, [entities]);

  const entity = useMemo(() => {
    return entities.find(entity => entity._id === selectedEntity);
  }, [entities, selectedEntity]);

  const action = useMemo(() => {
    return entity?.actionPlan?.actions.find(
      action =>
        action.id === selectedAction?.id &&
        action.relatedProductId === selectedAction?.relatedProductId &&
        action.versionProductId === selectedAction?.versionProductId &&
        action.subContractor.name === selectedAction?.subContractor?.name
    );
  }, [entity?.actionPlan?.actions, selectedAction]);

  const handleClickEntity = (name, entityId) => {
    if (selectedEntity !== entityId) {
      setSelectedEntity(entityId);
      if (selectedAction) {
        setSelectedAction(null);
      }
    }
  };

  useDidUpdate(async () => {
    const subContractorId = selectedAction?.subContractor?.id;
    if (!subContractorId) {
      setSubContractorData(null);
      return;
    }

    try {
      const result = await fetchSubContractorResponses(subContractorId);

      if (result && Object.keys(result).length > 0) {
        setSubContractorData(result);
      } else {
        setSubContractorData(null);
      }
    } catch (error) {
      console.error("Error fetching subcontractor responses:", error);
      setSubContractorData(null);
    }
  }, [selectedAction?.subContractor?.id]);

  const handleClickAction = action => {
    const { id, versionProductId, relatedProductId, subContractor } = action;
    setSelectedAction({ id, versionProductId, relatedProductId, subContractor });
  };

  const handleLegalOfficerDecision = async type => {
    setLoading(true);
    await dispatch(
      handleLegalOfficerAuditDecision({
        accountId: account._id,
        entityId: entity._id,
        actionId: action.id,
        relatedProductId: action.relatedProductId,
        versionProductId: action.versionProductId,
        type,
        subContractorId: action.subContractor.id,
      })
    );
    const subContractorId = selectedAction?.subContractor?.id;
    const result = await fetchSubContractorResponses(subContractorId);
    if (result?.status && subContractorData) {
      setSubContractorData(prevData => { return { ...prevData, status: result.status } });
    }
    setLoading(false);
  };

  const formatSubcontractorQuestionAndResponses = (key, value) => {
    const questionInfo = getAuditQuestionInfosByName(surveyData, key);
    const questionLabel = questionInfo.label;
    if (Array.isArray(value)) {
      return { questionLabel, response: value.map(elt => questionInfo.options?.find(item => item.value === elt)?.label).join(', ') };
    }
    const response = questionInfo.options ? questionInfo.options?.find(item => item.value === value || value.includes(item.value))?.label : value;
    return { questionLabel, response };
  };

  return (
    <Container>
      <SectionContainer direction='column'>
        <InputComponent
          placeholder='Choisir une entité'
          type={'select'}
          options={selectEntitiesOptions}
          onChange={handleClickEntity}
          sharable={false}
          defaultValue={selectEntitiesOptions.length === 1 && selectEntitiesOptions[0]}
          noOptionsMessage={() => 'Aucune entité disponible'}
        />
        {selectedEntity && (
          <>
            <ArrowContainer>
              <IoIosArrowDown size={22} />
            </ArrowContainer>
            <ActionsContainer>
              <ContainerTitle>
                <span>List des Audits</span>
              </ContainerTitle>
              <hr />
              <ListContainer>
                <List>
                  {entity.actionPlan.actions
                    ?.filter(item => item.category === 'Audit')
                    .map((item, index) => {
                      return (
                        <ListItem
                          key={index}
                          onClick={() => handleClickAction(item)}
                          active={
                            item.id === selectedAction?.id &&
                            item.relatedProductId === selectedAction?.relatedProductId &&
                            item.versionProductId === selectedAction?.versionProductId &&
                            item.subContractor.name === selectedAction?.subContractor?.name
                          }>
                          <ActionStatus status={item.status} justifyContent='inherit' onlyIcon />
                          <span>Auditer : {item.subContractor.name}</span>
                          <IoIosArrowForward size={16} />
                        </ListItem>
                      );
                    })}
                </List>
              </ListContainer>
            </ActionsContainer>
          </>
        )}
      </SectionContainer>
      {selectedAction && (
        <ActionDetailsContainer>
          {subContractorData ? (
            <>
              <Div grid columnTemplate='3fr 1fr' gap='30px'>
                <Div flex column>
                  <DivContainer center bolder>
                    <span>Nom de l'entreprise</span>
                    {subContractorData.companyName}
                  </DivContainer>
                  <DivContainer center bolder >
                    <span>Email de responsable</span>
                    {subContractorData.responsableEmail}
                  </DivContainer>
                  <DivContainer center bolder >
                    <span>Rôle de responsable</span>
                    {subContractorData.responsablePosition}
                  </DivContainer>
                </Div>
                <ActionFooter>
                  {
                    !subContractorData.status ? (
                      loading ? (
                        <CustomSpinner color={BUTTON_COLORS.default} />
                      ) : (
                        <>
                          <Button
                            label='valider'
                            icon={FaCheck}
                            type='success'
                            onClick={() => handleLegalOfficerDecision('validate')}
                            disabled={loading}
                          />
                          <Button
                            label='Refuser'
                            icon={ImCancelCircle}
                            type='danger'
                            onClick={() => handleLegalOfficerDecision('refuse')}
                            disabled={loading}
                          />
                        </>
                      )
                    ) : subContractorData?.status === 'conforme' ? (
                      <Chips label={'Conforme'} color='#00b894' icon={FaCheck} />
                    ) : (
                      <>
                        <Chips label={'Non Conforme'} color='#ff7675' icon={FaXmark} />
                        <Button
                          label='Revalider'
                          icon={FaArrowsRotate}
                          type='success'
                          onClick={() => handleLegalOfficerDecision('validate')}
                          disabled={loading}
                          iconSize={16}
                        />
                      </>
                    )

                    // TODO!: To be added if needed when the decision was made
                    //   <strong>
                    //     {dayjs(
                    //       action?.subContractor?.status === 'conforme' ? action?.subContractor?.validatedAt : action?.subContractor?.rejectedAt
                    //     ).format('DD MMMM YYYY [à] HH:mm:ss')}
                    //   </strong>
                  }
                </ActionFooter>
              </Div>
              <ResponsesHeader>Les réponses du sous-traitant : </ResponsesHeader>
              <ResponseContainer>
                {subContractorData?.responses && Object.entries(subContractorData.responses).map(([key, value]: [string, string]) => {
                  const { questionLabel, response } = formatSubcontractorQuestionAndResponses(key, value);
                  const regex = /\.(pdf|docx|xlsx)$/i;
                  return (
                    <SharedItem
                      questionValue={response}
                      value={questionLabel}
                      valid={true}
                      key={key}
                      type='questions'
                      cursor={regex.test(value) ? 'pointer' : null}
                      onClickFn={() => {
                        if (regex.test(value)) {
                          setShowAvailableDocuments({ show: true, fileName: value });
                        }
                      }}
                    />
                  );
                })}
              </ResponseContainer>
            </>
          ) : subContractorData?.contactEmail ? (
            <Div flex alignItems={'center'} justifyContent={'center'} height={'100%'} column>
              <FaRegFaceGrinBeamSweat size={40} />
              <p>Le sous-traitant n'as pas encore répondu au question.</p>
            </Div>
          ) : (
            <Div flex alignItems={'center'} justifyContent={'center'} height={'100%'} column>
              <FaRegFaceGrimace size={40} />
              <p>Le client n'a pas encore ajouté le contact du sous-traitant.</p>
            </Div>
          )}
        </ActionDetailsContainer>
      )}

      {selectedEntity && showAvailableDocuments.show && selectedAction && (
        <Modal
          size={{ height: '100%' }}
          show={showAvailableDocuments?.show}
          title={t('actionPlan.available_documents')}
          onClose={() => setShowAvailableDocuments({ show: false, fileName: null })}>
          <FileExplorer
            selectedEntity={{ _id: selectedEntity }}
            selectedFileKey={`public/${account._id}/${selectedEntity}/Audits/${selectedAction.subContractor?.name}/${showAvailableDocuments.fileName}`}
            showEntitySelection={false}
            disableBreadcrumb
          />
        </Modal>
      )}
    </Container>
  );
};

export default Audits;

const ResponseContainer = styled.div`
  overflow-y: auto;
  padding-right: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;

const ListContainer = styled.div`
  overflow-y: auto;
  // height: 90%;
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  height: 100%;
  grid-column-gap: 20px;
`;
const SectionContainer = styled.div<{ direction: string }>`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : 'row')};
`;
const ListItem = styled.div<{ active: boolean }>`
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  margin: 5px;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  ${({ active }) => active && 'box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px;'};
  &:hover{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px;
  }
`;

const ActionsContainer = styled.div`
  border-radius: 5px;
  padding: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  // height: 72vh;
  height: 100%;
  > :not(:last-child) {
    margin-bottom: 10px;
  }
  font-size: 1.1rem;
`;

const ActionDetailsContainer = styled.div`
  row-gap: 10px;
  border-radius: 5px;
  padding: 15px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  font-size: 1.1rem;

  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ActionFooter = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 10px;
  justify-content: flex-end;
  > :not(:last-child) {
    margin-right: 10px;
  }
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomSpinner = styled(Spinner)`
  margin-right: 50px;
`;

const ResponsesHeader = styled.span`
  display: block;
  color: black;
  font-weight: bold;
  margin-bottom: 10px;
`;

const DivContainer = styled.div<{ center?: boolean; editable?: boolean; size?: string; height?: boolean; bolder?: boolean }>`
  display: grid;
  height: ${({ height }) => (height ? '4rem' : 'unset')};
  align-items: center;
  grid-template-columns: 1fr 2fr;
  column-gap: 1%;
  margin-bottom: 0.75rem;
  align-items: ${({ center }) => (center ? 'center' : 'unset')};
  font-weight: ${({ bolder }) => (bolder ? 'bold' : 'unset')};
  color: grey;
  span {
    color: black;
    margin-right: 1rem;
    font-weight: bold;
  }
  .justify-text {
    text-align: justify;
  }

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: 1.2rem;
        `;
      default:
        return null;
    }
  }}
`;

const ContainerTitle = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  span {
    font-weight: bold;
    font-size: 1.2rem;
  }
`;

const List = styled.div`
  padding-right: 10px;
  overflow-y: auto;
  > :not(:last-child) {
    margin-bottom: 10px;
  }
`;
