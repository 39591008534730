import { useState } from 'react';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { IoArrowForwardCircleOutline } from 'react-icons/io5';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { TaskItemProps } from './TaskItem.type';
import {
  ArrowContainer,
  CheckedIconContainer,
  TaskItemContainer,
  TitleContainer,
  SubMenuContainer,
  SubTaskItemContainer,
  SubTaskItemTitleContainer,
} from './TaskItem_.style';
import { addEventTracking } from '@utils/utils';

const TaskItem = ({ title, onClick, checked, disabled, subMenu }: TaskItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    if (subMenu) {
      setIsExpanded(!isExpanded);
    }
  };

  const isAllSubTasksChecked = () => {
    return subMenu ? subMenu.every(subTask => subTask.checked) : checked;
  };

  const handleClickTask = () => {
    addEventTracking(title, 'Click');
    onClick();
  };
  return (
    <div>
      <TaskItemContainer
        onClick={!disabled ? (subMenu ? handleExpand : !checked ? handleClickTask : null) : null}
        checked={isAllSubTasksChecked()}
        disabled={disabled}>
        <CheckedIconContainer checked={isAllSubTasksChecked()} disabled={disabled}>
          <IoMdCheckmarkCircleOutline />
          <TitleContainer disabled={disabled} checked={isAllSubTasksChecked()}>
            {title}
          </TitleContainer>
        </CheckedIconContainer>

        <ArrowContainer checked={isAllSubTasksChecked()} disabled={disabled}>
          {subMenu && !isAllSubTasksChecked() ? isExpanded ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : <IoArrowForwardCircleOutline />}
        </ArrowContainer>
      </TaskItemContainer>

      {isExpanded && subMenu && !isAllSubTasksChecked() && (
        <SubMenuContainer>
          {subMenu.map((subTask, index) => (
            <SubTaskItemContainer
              key={index}
              checked={subTask.checked}
              disabled={subTask.disabled}
              onClick={!subTask.disabled ? (!subTask.checked ? subTask.onClick : null) : null}>
              <CheckedIconContainer checked={subTask.checked} disabled={subTask.disabled}>
                <SubTaskItemTitleContainer disabled={subTask.disabled} checked={subTask.checked}>
                  {subTask.title}
                </SubTaskItemTitleContainer>
              </CheckedIconContainer>
            </SubTaskItemContainer>
          ))}
        </SubMenuContainer>
      )}
    </div>
  );
};

export default TaskItem;
