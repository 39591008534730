import {
  ButtonContainer,
  Chips,
  ChipsButton,
  ChipsContainer,
  EmailError,
  Empty,
  InputContainer,
  ModalShareContainer,
  UserItem,
  UserListContainer,
  UserListSlider,
  UserRow,
} from './MadalShare.style';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md';
import { SlUserUnfollow } from 'react-icons/sl';
import { Button } from '../Button';
import { InputComponent } from '../form/InputComponent';
import { Initial } from '../initials/Initial';
import { selectActiveAccount, selectAllAccounts } from '../../reducers/account.reducer';
import { isEmailValid } from '../../utils/utils';
import { COLORS } from '../../utils/constants';

const ModalShareListUser = ({
  type,
  shareID,
  isAlreadyShared,
  onClose,
  handleShare,
  emailInputList,
  setEmailInputList,
  commentInput,
  setCommentInput,
  usersChecked,
  setUsersChecked,
}) => {
  const { t } = useTranslation();
  // REDUX
  const allAccounts = useSelector(selectAllAccounts);
  const activeAccount = useSelector(selectActiveAccount);
  // STATE
  const [emailInputValue, setEmailInputValue] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailAlreadyUsed, setEmailAlreadyUsed] = useState(false);

  const handleSelectUser = user => {
    if (!isChecked(user)) {
      setUsersChecked([...usersChecked, user._id]);
    } else {
      let usersCheckedCopy = usersChecked.filter(userCopy => userCopy !== user._id);
      setUsersChecked(usersCheckedCopy);
    }
  };
  const handleEmailChange = e => {
    setEmailInputValue(e.target.value);
    setEmailError(false);
    setEmailAlreadyUsed(false);
  };
  const handleCommentChange = e => {
    setCommentInput(e.target.value);
  };
  const removeEmail = emailToDelete => {
    setEmailInputList(emailInputList.filter(email => email !== emailToDelete));
  };
  const handleEmailValidation = e => {
    if (emailInputValue === '') return;
    if (['Enter', 'Tab', ','].includes(e.key) || e.type === 'blur') {
      e.preventDefault();
      let email = emailInputValue.trim();
      let error = !isEmailValid(email) || isEmailAlreadyUsed(email);
      if (!isEmailValid(email)) {
        setEmailError(true);
      }
      if (isEmailAlreadyUsed(email)) {
        setEmailAlreadyUsed(true);
      }
      if (email && !error) {
        setEmailInputList([...emailInputList, email]);
        setEmailInputValue('');
        setEmailError(false);
        setEmailAlreadyUsed(false);
      }
    }
  };

  const isEmailAlreadyUsed = email => {
    if (email === '') return false;
    let emailFound = false;
    allAccounts.forEach(account => {
      if (account.users.some(user => user.email === email)) {
        emailFound = true;
      }
    });
    if (emailInputList.some(emailCheck => emailCheck === email)) {
      emailFound = true;
    }
    return emailFound;
  };

  const isChecked = user => {
    return usersChecked.some(userCopy => userCopy === user._id);
  };

  const getTypeLabel = itemType => {
    switch (itemType) {
      case 'questions':
        return t('common.question');
      case 'surveySteps':
        return t('common.step');
      case 'surveyVersions':
        return t('common.survey');
      case 'surveys':
        return t('common.survey');
      case 'entities':
        return t('common.entity');
      case 'actions':
        return t('common.action');
      default:
        return '';
    }
  };

  return (
    <ModalShareContainer>
      <>
        <InputContainer>
          <ChipsContainer>
            {emailInputList?.map(
              (email, index) =>
                email && (
                  <Chips key={index}>
                    {email}
                    <ChipsButton onClick={() => removeEmail(email)}>
                      <MdClose />
                    </ChipsButton>
                  </Chips>
                )
            )}
          </ChipsContainer>
          <InputComponent
            type={'text'}
            placeholder={t('modalShare.add_email_placeholder')}
            onChange={handleEmailChange}
            onKeyDown={handleEmailValidation}
            onBlur={handleEmailValidation}
            value={emailInputValue}
          />
          {(emailError || emailAlreadyUsed) && (
            <EmailError>
              {' '}
              {emailInputValue} {emailError ? t('modalShare.invalid_email_error') : t('modalShare.email_already_used_error')}
            </EmailError>
          )}
        </InputContainer>
        {activeAccount?.users.filter(user => user.role === 'guest').filter(user => !isAlreadyShared(user, type, shareID)).length > 0 ? (
          <span>{t('modalShare.account_users')}</span>
        ) : activeAccount.users.length > 1 ? (
          <Empty>
            <span
              dangerouslySetInnerHTML={{
                __html: `${t('modalShare.shared_already', { type: getTypeLabel(type) })} ${t('modalShare.share_new_email')}`,
              }}></span>
          </Empty>
        ) : (
          <Empty>
            <SlUserUnfollow />
            <span
              dangerouslySetInnerHTML={{
                __html: `${t('modalShare.no_collaborators', { type: getTypeLabel(type) })} ${t('modalShare.add_new_email')} `,
              }}></span>
          </Empty>
        )}
        <UserListSlider>
          <UserListContainer>
            {[...activeAccount?.users]
              .sort((a, b) => isAlreadyShared(a, type, shareID) - isAlreadyShared(b, type, shareID))
              ?.map(
                (user, index) =>
                  user && (
                    <UserRow
                      onClick={() => handleSelectUser(user)}
                      key={index}
                      checked={isChecked(user)}
                      alreadyShared={isAlreadyShared(user, type, shareID)}>
                      <Initial
                        name={user?.name !== '' ? user.name : user?.email}
                        firstName={user?.firstName}
                        color={isAlreadyShared(user, type, shareID) ? COLORS.Green : null}
                        userId={user?._id}
                      />
                      <UserItem> {user?.firstName ? user?.name + ' ' + user?.firstName : user?.name || user?.email}</UserItem>
                      {!isAlreadyShared(user, type, shareID) ? (
                        <input
                          checked={isChecked(user)}
                          onChange={() => handleSelectUser(user)}
                          id={`checkbox-${index}`}
                          name={user?.firstName ? user?.name + ' ' + user?.firstName : user?.name}
                          type={'checkbox'}
                        />
                      ) : (
                        <span>{t('modalShare.already_shared')}</span>
                      )}
                    </UserRow>
                  )
              )}
          </UserListContainer>
        </UserListSlider>
        <InputComponent type={'text'} placeholder={t('modalShare.add_comment_placeholder')} onChange={handleCommentChange} value={commentInput} />
        <ButtonContainer>
          <Button label={t('common.cancel')} onClick={onClose} />
          <Button disabled={usersChecked.length < 1 && emailInputList.length < 1} label={t('common.share')} onClick={handleShare} />
        </ButtonContainer>
      </>
    </ModalShareContainer>
  );
};

export default ModalShareListUser;
