import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DisabledSurvey,
  List,
  ListTitle,
  RightsContainer,
  SectionContainer,
  UserActionContainer,
  UserDetailsTop,
} from './AdminAuthorizations_.style';

import { disableSurvey, selectAccount, selectSelectedAccount, updateRights } from '../../reducers/account.reducer';
import { selectDocuments, selectRegistries, selectSurveys } from '../../reducers/contentful.reducer';
import _ from 'lodash';
import { getAuthorizedSurveysByQuestions, isSurveyDisabled } from '@utils/utils';
import { ModalAuthorizedSurveys } from '@components/modal-authorized-surveys/ModalAuthorizedSurveys';
import { toast } from 'react-toastify';
import { ACCOUNT_TYPES } from '@utils/constants';
import { Button } from '@components/Button';

export const AdminAuthorizations = () => {
  //REDUX
  const dispatch = useDispatch<any>();
  const account = useSelector(selectAccount);
  const selectedAccount = useSelector(selectSelectedAccount);
  const contentfulDocuments = useSelector(selectDocuments);
  const contentfulRegistries = useSelector(selectRegistries);
  const contentfulSurveys = useSelector(selectSurveys);
  //STATE
  const [currentActionRights, setCurrentActionRights] = useState(selectedAccount?.actionRights);
  const [showModal, setShowModal] = useState(false);
  const [blockingSurveys, setBlockingSurveys] = useState([]);
  const [questionsConflicts, setQuestionsConflicts] = useState([]);

  const checkActionRightsConflicts = async (actionRights: any) => {
    const authorizedSurveysByQuestions = await getAuthorizedSurveysByQuestions(actionRights.surveys);
    let newQuestionsConflicts = [];
    let newBlockingSurveys = [];
    for (const [question, surveysArray] of Object.entries(authorizedSurveysByQuestions)) {
      if (Array.isArray(surveysArray) && surveysArray.length > 1) {
        newQuestionsConflicts.push({ name: question, surveys: surveysArray });
        if (surveysArray.filter(survey => !isSurveyDisabled(survey.id, true)).length > 1) {
          newBlockingSurveys = newBlockingSurveys.concat(surveysArray.filter(survey => !isSurveyDisabled(survey.id, true)));
        }
      }
    }
    newBlockingSurveys = newBlockingSurveys.filter((value, index, self) => index === self.findIndex(t => t.id === value.id));
    newQuestionsConflicts = newQuestionsConflicts.filter(
      question => question.surveys.filter(survey => !isSurveyDisabled(survey.id, true)).length > 1
    );

    setQuestionsConflicts(newQuestionsConflicts);
    setBlockingSurveys(newBlockingSurveys);
    if (newQuestionsConflicts.length > 0) {
      setShowModal(true);
    }
  };

  const getMissingSurveys = () => {
    let missingSurveys = [];
    let CTFsurveys = contentfulSurveys?.map(survey => survey.id);

    if (CTFsurveys) {
      currentActionRights?.surveys.forEach(survey => {
        if (!CTFsurveys.includes(survey.id)) {
          missingSurveys.push(survey);
        }
      });
    }
    return missingSurveys;
  };

  const getDisabledSurveys = () => {
    return currentActionRights.surveys.filter(survey => isSurveyDisabled(survey.id, true));
  };

  const getContentfulEnabledSurveys = () => {
    return contentfulSurveys.filter(survey => !isSurveyDisabled(survey.id, true));
  };

  const handleDisableSurvey = async surveyId => {
    await dispatch(disableSurvey({ accountId: selectedAccount._id, surveyId: surveyId, disabled: !isSurveyDisabled(surveyId, true) }));
    await checkActionRightsConflicts(currentActionRights);
    toast.success(`Le questionnaire a bien été ${isSurveyDisabled(surveyId, true) ? 'désactivé' : 'réactivé'}`);
  };

  const handleToggleDocument = docx => {
    const newActionRights = _.cloneDeep(currentActionRights);
    if (isDocumentChecked(docx)) {
      newActionRights.documents = newActionRights.documents.filter(currentDocx => currentDocx.id !== docx.id);
    } else {
      newActionRights.documents.push({ id: docx.id, label: docx.name });
    }
    setCurrentActionRights(newActionRights);
  };

  const handleToggleRegistry = registry => {
    const newActionRights = _.cloneDeep(currentActionRights);
    if (isRegistryChecked(registry)) {
      newActionRights.registries = newActionRights.registries.filter(currentRegistry => currentRegistry.id !== registry.id);
    } else {
      newActionRights.registries.push({ id: registry.id, label: registry.name });
    }
    setCurrentActionRights(newActionRights);
  };

  const handleToggleSurvey = async (survey, save) => {
    const newActionRights = _.cloneDeep(currentActionRights);
    if (isSurveyChecked(survey)) {
      newActionRights.surveys = newActionRights.surveys.filter(currentSurvey => currentSurvey.id !== survey.id);
    } else {
      newActionRights.surveys.push({ id: survey.id, label: survey.name ?? survey.label });
    }
    setCurrentActionRights(newActionRights);
    await checkActionRightsConflicts(newActionRights);
    if (save) {
      await saveSelectedAccountRights(newActionRights);
    }
  };

  const isDocumentChecked = docx => {
    if (!currentActionRights) {
      return false;
    }
    return currentActionRights.documents.some(currentDocx => currentDocx.id === docx.id);
  };

  const isRegistryChecked = registry => {
    if (!currentActionRights) {
      return false;
    }
    return currentActionRights.registries.some(currentRegistry => currentRegistry.id === registry.id);
  };

  const isSurveyChecked = survey => {
    if (!currentActionRights) {
      return false;
    }
    return currentActionRights.surveys.some(currentSurvey => currentSurvey.id === survey.id);
  };

  const saveSelectedAccountRights = (newActionRights = false) => {
    dispatch(
      updateRights({
        accountId: selectedAccount._id,
        actionRights: newActionRights ? newActionRights : currentActionRights,
      })
    );
  };

  useEffect(() => {
    (async () => {
      await checkActionRightsConflicts(currentActionRights);
    })();
  }, [currentActionRights]);

  return (
    <>
      <UserDetailsTop>
        <UserActionContainer>
          {account?.accountType !== ACCOUNT_TYPES.AGENCY && (
            <Button
              type={'success'}
              onClick={() => saveSelectedAccountRights()}
              label={'Mettre à jour les autorisations'}
              disabled={questionsConflicts?.length > 0}
            />
          )}
        </UserActionContainer>
      </UserDetailsTop>
      <RightsContainer>
        <SectionContainer>
          <List>
            {getMissingSurveys().length > 0 && <ListTitle>Questionnaires manquants sur CTF</ListTitle>}
            {getMissingSurveys().map((survey, index) => (
              <React.Fragment key={`${survey.label}-${index}`}>
                <label className={'missing'} htmlFor={`surveyID-${survey.id}`}>
                  <input
                    checked={isSurveyChecked(survey)}
                    type='checkbox'
                    name={`survey-${survey.id}`}
                    id={`surveyID-${survey.id}`}
                    onChange={() => handleToggleSurvey(survey, true)}
                  />
                  {survey.label}
                </label>
              </React.Fragment>
            ))}
            {getDisabledSurveys().length > 0 && <ListTitle>Questionnaires désactivés</ListTitle>}
            {getDisabledSurveys().map((survey, index) => (
              <DisabledSurvey key={`${survey.label}-${index}`} onClick={() => handleDisableSurvey(survey.id)}>
                <label className={'disabled'} htmlFor={`surveyID-${survey.id}`}>
                  {survey.label}
                </label>
                <label className={'reactivate'}>Réactiver</label>
              </DisabledSurvey>
            ))}
            {getContentfulEnabledSurveys().length > 0 && <ListTitle>Questionnaires autorisés</ListTitle>}
            {getContentfulEnabledSurveys().map((survey, index) => (
              <React.Fragment key={`${survey.name}-${index}`}>
                <label htmlFor={`surveyID-${survey.id}`}>
                  <input
                    checked={isSurveyChecked(survey)}
                    type='checkbox'
                    name={`survey-${survey.id}`}
                    id={`surveyID-${survey.id}`}
                    onChange={() => handleToggleSurvey(survey, false)}
                  />
                  {survey.name}
                </label>
              </React.Fragment>
            ))}
          </List>
          <List>
            <ListTitle>Documents authorisés</ListTitle>
            {contentfulDocuments.map((document, index) => {
              return (
                <React.Fragment key={`${document.name}-${index}`}>
                  <label htmlFor={`surveyID-${document.id}`}>
                    <input
                      checked={isDocumentChecked(document)}
                      type='checkbox'
                      name={`survey-${document.id}`}
                      id={`surveyID-${document.id}`}
                      onChange={() => handleToggleDocument(document)}
                    />
                    {document.name}
                  </label>
                </React.Fragment>
              );
            })}
          </List>
          {contentfulRegistries.length > 0 && (
            <List>
              <ListTitle>Registres autorisés</ListTitle>
              {contentfulRegistries.map((registry, index) => {
                return (
                  <React.Fragment key={`${registry.name}-${index}`}>
                    <label htmlFor={`surveyID-${registry.id}`}>
                      <input
                        checked={isRegistryChecked(registry)}
                        type='checkbox'
                        name={`survey-${registry.id}`}
                        id={`surveyID-${registry.id}`}
                        onChange={() => handleToggleRegistry(registry)}
                      />
                      {registry.name}
                    </label>
                  </React.Fragment>
                );
              })}
            </List>
          )}
        </SectionContainer>
        {showModal && (
          <ModalAuthorizedSurveys
            show={showModal}
            onClose={() => setShowModal(false)}
            onSave={() => saveSelectedAccountRights()}
            onDisableSurvey={surveyId => handleDisableSurvey(surveyId)}
            questionsConflicts={questionsConflicts}
            blockingSurveys={blockingSurveys}
          />
        )}
      </RightsContainer>
    </>
  );
};
