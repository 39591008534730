import { COLORS } from '@utils/constants';
import styled from 'styled-components';

export const TaskItemContainer = styled.div<{ checked: boolean; disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  min-width: 300px;
  padding: 10px;
  border: 1px solid ${COLORS.MediumGrey};
  border-radius: 15px;
  cursor: ${({ checked, disabled }) => (!disabled && !checked ? 'pointer' : 'not-allowed')};
  ${({ disabled, checked }) =>
    (disabled || checked) &&
    `
    background-color: #e0e0e0;
    ;
  `}

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const CheckedIconContainer = styled.div<{ checked: boolean; disabled: boolean }>`
  flex: 4;
  display: flex;
  gap: 5px;
  align-items: center;
  svg {
    color: ${({ checked, disabled }) => (!disabled ? (checked ? COLORS.Green : COLORS.NiceBlue) : COLORS.MediumGrey)};
  }
`;

export const TitleContainer = styled.div<{ checked: boolean; disabled: boolean }>`
  flex: 4;
  color: ${COLORS.Black};
  ${({ disabled, checked }) =>
    !disabled &&
    checked &&
    `
    text-decoration: line-through;
    color: ${COLORS.DarkGrey};
  `}
  ${({ disabled }) =>
    disabled &&
    `
    color: ${COLORS.MediumGrey};
  `}
`;

export const ArrowContainer = styled.div<{ checked: boolean; disabled: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: end;
  color: ${({ checked, disabled }) => (disabled || checked ? COLORS.MediumGrey : COLORS.NiceBlue)};
`;

export const SubMenuContainer = styled.div`
  padding-left: 35px;
  margin-top: 10px;
`;

export const SubTaskItemContainer = styled.div<{ checked: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  cursor: ${({ checked, disabled }) => (!disabled && !checked ? 'pointer' : 'not-allowed')};
  border-radius: 15px;
  padding: 0 10px;
  ${({ disabled, checked }) =>
    (disabled || checked) &&
    `
    background-color: ${COLORS.LightGrey};
  `}
  svg {
    width: 15px;
    height: 15px;
  }
`;

export const SubTaskItemTitleContainer = styled.div<{ checked: boolean; disabled: boolean }>`
  flex: 4;
  color: ${COLORS.Black};
  ${({ disabled, checked }) =>
    !disabled &&
    checked &&
    `
    text-decoration: line-through;
    color: ${COLORS.DarkGrey};
  `}
  ${({ disabled }) =>
    disabled &&
    `
    color: ${COLORS.MediumGrey};
  `}
  ${({ checked }) =>
    checked &&
    `
    color: ${COLORS.Green};
  `}
  text-decoration: underline;
`;
