import styled, { css } from 'styled-components';
import { IconType } from 'react-icons';

import { BUTTON_COLORS } from '../utils/constants';

export interface ButtonProps {
  className?: string;
  label: string;
  type?: 'danger' | 'admin' | 'success' | 'light' | undefined;
  //TODO: change light by outlined everywhere
  outlined?: boolean;
  icon?: IconType;
  onClick?: any;
  name?: string;
  style?: any;
  disabled?: boolean;
  customColor?: string;
  iconSize?: number;
}

export const Button = ({ className, label, type, icon, onClick, name, style, disabled, customColor, iconSize }: ButtonProps) => {
  let Icon = icon;
  return (
    <ButtonContainer
      className={className}
      disabled={disabled}
      style={{ ...style }}
      // name={name}
      type={type}
      onClick={e => !disabled && onClick(e)}
      customColor={customColor}>
      {icon && <Icon size={iconSize || 20} />}
      <span>{label}</span>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div<{ type: string; disabled?: boolean; customColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  column-gap: 5px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
  width: fit-content;
  height: fit-content;
  text-transform: uppercase;
  font-family: 'Roboto', serif;
  font-weight: ${({ type }) => (type === 'light' ? 700 : 500)};
  ${({ type }) => type !== 'light' && 'box-shadow: 2px 1px 2px rgb(0 0 0 / 14%);'};
  letter-spacing: 1.25px;
  white-space: nowrap;
  font-size: 16px;
  position: relative;
  user-select: none;
  border: 1px solid transparent;

  background-color: ${({ type, disabled, customColor }) => {
    if (!disabled) {
      switch (type) {
        case 'danger':
          return BUTTON_COLORS.danger;
        case 'admin':
          return BUTTON_COLORS.admin;
        case 'success':
          return BUTTON_COLORS.success;
        case 'light':
          return 'none';
        default:
          if (customColor) {
            return customColor;
          }
          return BUTTON_COLORS.default;
      }
    } else {
      return BUTTON_COLORS.disabled;
    }
  }};

  > span {
    color: ${({ type }) => (type === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'white')};
    font-weight: 500;
    font-size: 0.9em !important;
    transition: color 0.2s ease-in-out;
  }

  ${({ disabled }) =>
    !disabled &&
    `
      &:hover {
        background-color: ${({ type }) => {
      switch (type) {
        case 'danger':
          return BUTTON_COLORS.dangerHover;
        case 'admin':
          return BUTTON_COLORS.adminHover;
        case 'success':
          return BUTTON_COLORS.successHover;
        case 'light':
          return 'none';
        default:
          return BUTTON_COLORS.defaultHover;
      }
    }};
      }
    `};

  svg {
    // width: 20px;
    // height: 20px;
    color: ${({ type }) => (type === 'light' ? 'black' : 'white')};
    ${({ type }) => type === 'light' && `opacity: 0.5`};
    transition: all 0.2s ease-in-out;
  }

  ${({ type }) =>
    type === 'light' &&
    css`
      &:hover {
        border-radius: 50px;
        border: 1px solid rgba(0, 0, 0, 0.5);

        &:before {
          opacity: 1;
        }

        > span {
          color: rgba(0, 0, 0, 0.8);
        }
        svg {
          opacity: 1;
        }
      }
    `};
`;
