import styled from 'styled-components';
import { COLORS } from '@utils/constants';

export const Content = styled.div`
  padding: 20px;
  width: calc(100% - 40px);
  display: flex;
  column-gap: 15px;
  height: calc(100vh - 102px);
  flex-direction: column;
  overflow-y: auto;
`;

export const DisabledSurvey = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > label {
    &.disabled {
      flex: 2;
      text-decoration: line-through;
      color: ${COLORS.MediumGrey};
    }
    &.reactivate {
      flex: 3;
      cursor: pointer;
      color: ${COLORS.DarkGreen};
      text-decoration: underline;
    }
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
  flex: 1;

  > label {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;

    > input {
      margin: 0;
    }

    &.missing {
      color: red;
    }
  }
`;

export const ListTitle = styled.div`
  font-size: 1.3em;
  font-weight: 500;
  color: #187ead;
  border-bottom: 1px solid #e9edf7;
  margin-bottom: 10px;
  width: max-content;
`;

export const RightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  width: 100%;
  //padding-bottom: 30px;
`;

export const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;
  height: 100%;

  label {
    display: flex;
    align-items: center;
  }

  label div {
    width: 140px;
  }

  label input {
    margin-right: 10px;
    margin-left: 10px;
  }
`;

export const UserActionContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  justify-content: flex-end;
  padding: 20px 0;
`;

export const UserDetailsTop = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  width: calc(100% - 40px);
  color: black;
  column-gap: 20px;
  justify-content: space-between;
`;
