import { t } from 'i18next';

import { MdDone } from 'react-icons/md';
import { PiHourglass } from 'react-icons/pi';
import { TbProgress } from 'react-icons/tb';

export const actionsStatus = {
  pending: { title: t('common.status_to_handle'), icon: PiHourglass, color: '#DD0615' },
  ongoing: { title: t('common.status_ongoing'), icon: TbProgress, color: '#F49D1D' },
  completed: { title: t('common.status_completed'), icon: MdDone, color: '#7BDFC8' },
};
export const actionsUrgencyComplexity = {
  low: t('common.low'),
  medium: t('common.medium'),
  high: t('common.high'),
};

//
// Selects Options mapper
//

export const urgencyComplexity = [
  {
    value: 'low',
    label: t('common.low'),
  },
  {
    value: 'medium',
    label: t('common.medium'),
  },
  {
    value: 'high',
    label: t('common.high'),
  },
];
export const status = [
  {
    value: 'pending',
    label: t('common.status_to_handle'),
  },
  {
    value: 'ongoing',
    label: t('common.status_ongoing'),
  },
  {
    value: 'completed',
    label: t('common.status_completed'),
  },
];
export const category = [
  {
    value: 'Marketing',
    label: t('common.Marketing'),
  },
  {
    value: 'RH',
    label: t('common.RH'),
  },
  {
    value: 'Tech',
    label: t('common.Tech'),
  },
  {
    value: 'Audit',
    label: t('common.Audit'),
  },
];
