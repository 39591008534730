import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { ImFilesEmpty } from 'react-icons/im';

import { SharedItem } from './SharedItem';
import { Initial } from '../initials/Initial';
import { Button } from '../Button';
import { Modal } from '../modal/Modal';

import { removeUserFromAccount, selectActiveAccount } from '../../reducers/account.reducer';
import { selectSelectedCollaborator, setCurrentEntity, setCurrentStep, setCurrentSurvey, setCurrentVersion } from '../../reducers/app.reducer';
import { selectAllContentfulData } from '../../reducers/contentful.reducer';

import {
  getActionDetail,
  getActionProductName,
  getDetailedEntityById,
  getDetailedQuestionById,
  getDetailedStepById,
  getDetailedSurveyById,
  getLinkedQuestions,
  getResponsesBySurveyId,
  getSurveyStepFilledRequiredQuestions,
  getSurveyStepRequiredQuestions,
  getSurveyVersionById,
  getSurveyVersionFullList,
  removeSharedItem,
  totalSurveyCount,
} from '../../utils/utils';
import { COLORS } from '../../utils/constants';

import { paths } from 'src/routes/routes.constants';
import { useTranslation } from 'react-i18next';
// TODO : create a global ui constant file
import { actionsStatus } from '@pages/ActionPlanDash/ActionPlanDash.constants';

export interface CollaboratorsProps {}

const Collaborators = () => {
  const { t } = useTranslation();
  const selectedCollaborator = useSelector(selectSelectedCollaborator);
  const activeAccount = useSelector(selectActiveAccount);
  const history = useHistory();
  const dispatch = useDispatch<any>();
  const contentfulData = useSelector(selectAllContentfulData);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [shareGroups, setShareGroups] = useState({
    entities: [],
    questions: [],
    surveys: [],
    steps: [],
    actions: [],
  });
  const [deleteShareItem, setDeleteShareItem] = useState({
    show: false,
    entityId: '',
    shareId: '',
    shareName: '',
    title: '',
  });

  useEffect(() => {
    if (!selectedCollaborator) {
      history.push(paths.dashboardGroup);
    }
  });

  const showShareDeleteModal = (shareType, shareName, id) => {
    let title = `${t(`collaborators.delete.${shareType}`)}`;

    if (!title) {
      title = `${t('collaborators.delete.generic')}`;
    }

    if (shareType === 'actions') {
      const entityId = id.split('-')[1];
      setDeleteShareItem({
        show: true,
        entityId: entityId,
        shareId: id,
        shareName,
        title,
      });
    } else {
      setDeleteShareItem({
        show: true,
        entityId: id,
        shareId: shareType + '-' + id,
        shareName,
        title,
      });
    }
  };

  const closeDeleteModale = () => {
    setDeleteShareItem({
      show: false,
      entityId: '',
      shareId: '',
      shareName: '',
      title: '',
    });
  };

  const groupSharedQuestionsBySurvey = async () => {
    let shareGroupCopy = _.cloneDeep(shareGroups);
    for (let shareType in selectedCollaborator?.sharedItems) {
      switch (shareType) {
        case 'entities':
          shareGroupCopy.entities = selectedCollaborator?.sharedItems[shareType]
            .filter(entity => entity.shareTarget)
            .map(entity => {
              let detailedEntity = getDetailedEntityById(entity.entityId);
              let surveyCount = totalSurveyCount(detailedEntity);
              return {
                ...entity,
                shareName: detailedEntity?.name,
                valid: surveyCount?.totalSurveysCount === surveyCount?.completedSurveysCount,
                onClick: () => {
                  dispatch(setCurrentEntity(detailedEntity));
                  dispatch(setCurrentSurvey(contentfulData?.surveys?.find(survey => survey.index === 1)));
                  history.push(paths.dashboardEntity);
                },
                filling: {
                  total: surveyCount?.totalSurveysCount,
                  filled: surveyCount?.completedSurveysCount,
                },
                options: [
                  {
                    label: `${t(`collaborators.buttons.remove.${shareType}`)}`,
                    onClick: () => showShareDeleteModal('entity', detailedEntity?.name, entity.entityId),
                  },
                ],
              };
            });
          break;
        case 'questions':
          shareGroupCopy.questions = selectedCollaborator?.sharedItems[shareType]
            .filter(question => question.shareTarget)
            .map(async question => {
              let detailedEntity = getDetailedEntityById(question.entityId);
              let detailedQuestion = await getDetailedQuestionById(question.questionId, question.surveyId);
              let detailedSurvey = await getDetailedSurveyById(question.surveyId);
              let detailedVersion = getSurveyVersionById(detailedEntity, question.surveyId, question.surveyVersionId);
              let detailedStep = getDetailedStepById(question.surveyStepId, question.surveyId);
              let responses = getResponsesBySurveyId(question.surveyId, detailedEntity);
              let versionResponses = responses?.versions?.find(version => version?._id === question?.surveyVersionId)?.versionResponses;
              let linkedQuestions = getLinkedQuestions(
                question.surveyStepId,
                detailedQuestion,
                question.surveyId,
                question.surveyVersionId,
                question.entityId
              );
              let questionAndLinked = [];

              questionAndLinked.push({
                ...question,
                versionName: getSurveyVersionById(detailedEntity, question.surveyId, question.surveyVersionId)?.versionName,
                shareName: detailedQuestion?.label,
                value: versionResponses?.[detailedQuestion?.name],
                valid:
                  versionResponses?.[detailedQuestion?.name] !== undefined &&
                  versionResponses?.[detailedQuestion?.name] !== null &&
                  versionResponses?.[detailedQuestion?.name] !== '' &&
                  versionResponses?.[detailedQuestion?.name]?.length > 0,
                entityName: detailedEntity?.name,
                stepName: detailedStep?.name,
                onClick: () => {
                  dispatch(setCurrentSurvey(detailedSurvey));
                  dispatch(setCurrentEntity(detailedEntity));
                  dispatch(setCurrentVersion(detailedVersion));
                  let detailedStep = detailedSurvey.steps.find(step => step.id === question.surveyStepId);
                  dispatch(setCurrentStep({ id: detailedStep.id, name: detailedStep.name }));
                  history.push(paths.survey);
                },
                options: [
                  {
                    label: `${t(`collaborators.buttons.remove.${shareType}`)}`,
                    onClick: () => showShareDeleteModal('question', detailedQuestion?.label, question.questionId),
                  },
                ],
              });

              linkedQuestions?.forEach(linkedQuestion => {
                questionAndLinked.push({
                  linked: true,
                  versionName: getSurveyVersionById(detailedEntity, question.surveyId, question.surveyVersionId)?.versionName,
                  shareName: linkedQuestion?.label,
                  entityName: detailedEntity?.name,
                  stepName: detailedStep?.name,
                  valid: !!versionResponses?.[linkedQuestion.name],
                  value: versionResponses?.[linkedQuestion.name],
                });
              });

              return questionAndLinked;
            })
            .flat();
          break;
        case 'surveyVersions':
          let groupSurveys = selectedCollaborator?.sharedItems[shareType]
            .filter(survey => survey.shareTarget)
            .map(async survey => {
              let detailedEntity = getDetailedEntityById(survey.entityId);
              let detailedSurvey = await getDetailedSurveyById(survey.surveyId);
              let detailedVersion = getSurveyVersionById(detailedEntity, survey.surveyId, survey.surveyVersionId);
              let fullSurveyVersion = await getSurveyVersionFullList(detailedEntity, detailedSurvey, detailedVersion);
              let detailedStep = detailedSurvey.steps[0];

              return {
                ...survey,
                shareName: detailedVersion?.versionName,
                entityName: detailedEntity?.name,
                onClick: () => {
                  dispatch(setCurrentSurvey(detailedSurvey));
                  dispatch(setCurrentEntity(detailedEntity));
                  dispatch(setCurrentVersion(detailedVersion));
                  dispatch(setCurrentStep({ id: detailedStep.id, name: detailedStep.name }));
                  history.push(paths.survey);
                },
                valid: fullSurveyVersion.filter(step => step.fillingPercentage === 100).length === fullSurveyVersion.length,
                filling: {
                  filled: fullSurveyVersion.filter(step => step.fillingPercentage === 100).length,
                  total: fullSurveyVersion.length,
                },
                options: [
                  {
                    label: `${t(`collaborators.buttons.remove.${shareType}`)}`,
                    onClick: () => showShareDeleteModal('version', detailedVersion?.versionName, survey.surveyVersionId),
                  },
                ],
              };
            });
          let resolvedSurveys = await Promise.all(groupSurveys);
          shareGroupCopy.surveys = resolvedSurveys;
          break;
        case 'surveySteps':
          shareGroupCopy.steps = selectedCollaborator?.sharedItems[shareType]
            .filter(step => step.shareTarget)
            .map(async step => {
              let detailedEntity = getDetailedEntityById(step.entityId);
              let detailedSurvey = await getDetailedSurveyById(step.surveyId);
              let detailedVersion = getSurveyVersionById(detailedEntity, step.surveyId, step.surveyVersionId);
              let detailedStep = getDetailedStepById(step.surveyStepId, step.surveyId);
              let stepRequiredQuestions = await getSurveyStepRequiredQuestions(detailedEntity, detailedSurvey, detailedVersion, detailedStep.name);
              let filledQuestions = await getSurveyStepFilledRequiredQuestions(detailedEntity, detailedSurvey, detailedVersion, detailedStep.name);
              return {
                ...step,
                versionName: detailedVersion?.versionName,
                shareName: detailedStep?.name,
                entityName: getDetailedEntityById(step.entityId)?.name,
                surveyName: detailedSurvey?.name,
                valid: stepRequiredQuestions?.amount === filledQuestions?.amount,
                onClick: () => {
                  dispatch(setCurrentSurvey(detailedSurvey));
                  dispatch(setCurrentEntity(detailedEntity));
                  dispatch(setCurrentVersion(detailedVersion));
                  let detailedStep = detailedSurvey.steps.find(nextStep => nextStep.id === step.surveyStepId);
                  dispatch(setCurrentStep({ id: detailedStep.id, name: detailedStep.name }));
                  history.push(paths.survey);
                },
                filling: {
                  total: stepRequiredQuestions?.amount,
                  filled: filledQuestions?.amount,
                },
                options: [
                  {
                    label: `${t(`collaborators.buttons.remove.${shareType}`)}`,
                    onClick: () => showShareDeleteModal('step', detailedStep?.name, step.surveyStepId),
                  },
                ],
              };
            });
          break;
        case 'actions':
          shareGroupCopy.actions = selectedCollaborator?.sharedItems[shareType]
            .filter(action => action.shareId !== `entity-${action.entityId}` && action.shareId !== `entity/${action.entityId}`)
            .map(action => {
              const productName = getActionProductName(action.relatedProductId);
              let detailedAction = getActionDetail(action.entityId, action.actionId, action.relatedProductId, action.versionProductId);
              return {
                ...action,
                actionStatus: actionsStatus[detailedAction.status]?.title,
                shareName: detailedAction.title,
                entityName: getDetailedEntityById(action.entityId)?.name,
                surveyName: detailedAction.isEntityScoop ? null : productName,
                valid: detailedAction.status === 'completed',
                onClick: () => {
                  const { actionId, relatedProductId, entityId, versionProductId } = action;
                  history.push(`/plan-action/${actionId}|${relatedProductId}|${entityId}|${versionProductId}`);
                },
                options: [
                  {
                    label: `${t(`collaborators.buttons.remove.${shareType}`)}`,
                    onClick: () => showShareDeleteModal('actions', detailedAction?.name, action.shareId),
                  },
                ],
              };
            });
          break;
      }
    }
    setShareGroups(shareGroupCopy);
  };

  const getSectionTitle = shareType => {
    switch (shareType) {
      case 'entities':
        return t('collaborators.sections.shared_entities');
      case 'questions':
        return t('collaborators.sections.shared_questions');
      case 'surveys':
        return t('collaborators.sections.shared_surveys');
      case 'steps':
        return t('collaborators.sections.shared_steps');
      case 'actions':
        return t('collaborators.sections.shared_actions');
      default:
        return '';
    }
  };

  const getSharedDetails = () => {
    let sharedDetails = [];
    for (let shareType in shareGroups) {
      if (shareGroups[shareType].length === 0) continue;
      sharedDetails.push(
        <Access key={'key-' + shareType}>
          <span>{getSectionTitle(shareType)}</span>
          <AccessList>
            {shareGroups[shareType]?.map((shareItem, index) => {
              return (
                <SharedItem
                  questionValue={shareItem.value}
                  entityName={shareItem.entityName}
                  surveyName={shareItem.surveyName}
                  stepName={shareItem.stepName}
                  versionName={shareItem.versionName}
                  value={shareItem.shareName}
                  filling={shareItem.filling}
                  valid={shareItem.valid}
                  onClickFn={shareItem.onClick}
                  linked={shareItem.linked}
                  key={index}
                  type={shareType}
                  options={shareItem.options}
                  actionStatus={shareItem.actionStatus}
                />
              );
            })}
          </AccessList>
        </Access>
      );
    }

    if (sharedDetails.length === 0)
      sharedDetails.push(
        <Empty key={'empty-sharing'}>
          <ImFilesEmpty />
          <span>
            Vous ne partagez plus rien à{' '}
            <b>
              {(selectedCollaborator?.firstName ? selectedCollaborator?.name + ' ' + selectedCollaborator?.firstName : selectedCollaborator?.name) ||
                selectedCollaborator?.email}
            </b>
          </span>
        </Empty>
      );
    return sharedDetails;
  };

  const handleRemoveShare = async shareId => {
    removeSharedItem(selectedCollaborator?._id, shareId);
    closeDeleteModale();
  };

  const handleRemoveUserFromAccount = () => {
    dispatch(removeUserFromAccount({ userId: selectedCollaborator._id }));
    closeDeleteModale();
    history.push(paths.dashboardGroup);
  };

  useEffect(() => {
    (async () => {
      await groupSharedQuestionsBySurvey();
    })();
  }, [selectedCollaborator]);

  return (
    <>
      <CollaboratorsContainer>
        <ContentTitle>
          <div>
            <span>{activeAccount?.companyName}</span>
          </div>
        </ContentTitle>
        <Content>
          {selectedCollaborator && (
            <CollaboratorDetails>
              <DetailsTop>
                <Initial
                  name={selectedCollaborator?.name || selectedCollaborator?.email}
                  firstName={selectedCollaborator?.firstName}
                  userId={selectedCollaborator?._id}
                />
                <SelectedName>
                  {(selectedCollaborator?.firstName
                    ? selectedCollaborator?.name + ' ' + selectedCollaborator?.firstName
                    : selectedCollaborator?.name) || selectedCollaborator?.email}
                </SelectedName>
                <Info>({selectedCollaborator?.name === '' ? t('collaborators.invitation_pending') : selectedCollaborator?.email})</Info>
                <Button onClick={() => setShowDeleteModal(true)} type={'light'} label={t('common.delete')} />
              </DetailsTop>
              <SharedDetailsContainer>{getSharedDetails()}</SharedDetailsContainer>
            </CollaboratorDetails>
          )}
        </Content>
      </CollaboratorsContainer>
      <Modal
        show={showDeleteModal}
        title={t('collaborators.modals.delete_collaborator')}
        onClose={() => setShowDeleteModal(false)}
        size={{ width: '50%' }}>
        <BasicModalContainer>
          <p>
            {t('collaborators.modals.irreversible_action')}{' '}
            <b>
              {selectedCollaborator?.name} {selectedCollaborator?.firstName}
            </b>
            ?
          </p>
          <ButtonContainer>
            <Button type={'danger'} onClick={() => handleRemoveUserFromAccount()} label={t('common.delete')} />
            <Button type={'light'} onClick={() => setShowDeleteModal(false)} label={t('common.cancel')} />
          </ButtonContainer>
        </BasicModalContainer>
      </Modal>
      <Modal show={deleteShareItem?.show} title={deleteShareItem?.title} onClose={() => setShowDeleteModal(false)} size={{ width: '50%' }}>
        <BasicModalContainer>
          <p>
            {t('collaborators.modals.irreversible_action')} <b>{deleteShareItem.shareName}</b> avec{' '}
            <b>
              {selectedCollaborator?.name} {selectedCollaborator?.firstName}
            </b>{' '}
            ?
          </p>
          <ButtonContainer>
            <Button type={'danger'} onClick={() => handleRemoveShare(deleteShareItem.shareId)} label={t('common.delete')} />
            <Button type={'light'} onClick={closeDeleteModale} label={t('common.cancel')} />
          </ButtonContainer>
        </BasicModalContainer>
      </Modal>
    </>
  );
};

export default Collaborators;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
  margin-top: 50px;

  span {
    font-size: 1.3rem;
  }

  svg {
    color: ${COLORS.MediumGrey};
    width: 60px;
    height: 60px;
    animation: swing 0.8s ease 0.3s;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;

const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: center;
  row-gap: 26px;

  p {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Roboto', serif;
    line-height: 1.5em;
  }

  b {
    color: ${COLORS.softRed};
  }
`;

const SharedDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

const ContentTitle = styled.div`
  font-weight: 700;
  font-size: 2.6em;
  font-family: 'Nunito', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;

  div {
    font-size: 1.8rem;
    font-weight: 500;
    color: ${COLORS.lightGrey};
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
`;

const CollaboratorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 200px);
  width: calc(100% - 250px);
  padding: 100px 125px;
  row-gap: 50px;
  background-color: ${COLORS.LightBlue};
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  column-gap: 25px;
  min-height: 0;
`;

const CollaboratorDetails = styled.div`
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const DetailsTop = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: calc(100% - 50px);
  padding: 25px 25px 0 25px;
`;

const SelectedName = styled.div`
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  text-align: left;
`;

const Info = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1.1rem;
  color: ${COLORS.lightGrey};
  flex: 1;

  span {
    font-weight: 500;
  }
`;

const Access = styled.div`
  border-bottom: 1px solid ${COLORS.LightGrey};
  padding: 25px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &:last-child {
    border-bottom: none;
  }

  span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
  }
`;

const AccessList = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  align-items: center;
  row-gap: 10px;
`;
