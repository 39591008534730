import { COLORS } from '@utils/constants';
import styled from 'styled-components';

export const ModalShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Nunito';
  font-style: normal;
  font-size: 2em;
  flex: 1;
  row-gap: 20px;
  min-height: 0;
`;

export const LabelContainer = styled.div`
  display: flex;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Chips = styled.div`
  background-color: #c8e3f6;
  display: inline-flex;
  border-radius: 30px;
  height: 30px;
  font-size: 14px;
  padding: 0 4px 0 1rem;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
`;

export const ChipsButton = styled.div`
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 22px;
`;

export const EmailError = styled.div`
  font-size: 1.3rem;
  color: red;
`;

export const UserListSlider = styled.div`
  flex: 1;
  overflow-y: auto;
  min-height: 0;
`;
export const UserListContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
`;

export const UserRow = styled.div<{ checked: boolean; alreadyShared: boolean }>`
  display: flex;
  column-gap: 20px;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Roboto';
  font-size: 16px;
  width: calc(100% - 30px);
  font-weight: 500;
  padding: 15px;
  background-color: ${({ alreadyShared, checked }) => (alreadyShared ? '#E6FFE6' : checked ? '#C8E3F64D' : 'white')};
  border-bottom: 1px solid rgba(196, 196, 196, 0.37);
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  pointer-events: ${({ alreadyShared }) => (alreadyShared ? 'none' : 'auto')};

  &:hover {
    background-color: #c8e3f64d;
  }

  span {
    font-size: 1.125rem;
    color: ${COLORS.xlsx};
  }

  &:last-child {
    border-bottom: 0;
  }
`;

export const UserItem = styled.div`
  flex: 1;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 20px;
  font-family: 'Roboto';
`;

export const Empty = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  align-self: center;
  text-align: center;
  row-gap: 20px;

  svg {
    height: 40px;
    width: 40px;
    color: ${COLORS.MediumGrey};
    animation: swing 0.8s ease 0.7s;
  }

  span {
    font-size: 1.2rem;
    color: ${COLORS.Grey};
    line-height: 25px;
  }
`;
