import {
  selectShareModal,
  selectModal,
  selectCurrentEntity,
  setLoading,
  setModal,
  setCurrentSurvey,
  setCurrentVersion,
  setCurrentStep,
  selectErrors,
  setErrors,
  selectCurrentSurvey,
  selectCurrentVersion,
} from '../../reducers/app.reducer';
import { ModalSubmitEntity } from '../modal-submit-entity/ModalSubmitEntity';
import { MODAL_TYPES } from '../../utils/constants';
import { ModalSubmitEntityConfirmation } from '../modal-submit-entity-confirmation/ModalSubmitEntityConfirmation';
import { ModalSubmitEntityErrors } from '../modal-submit-entity-errors/ModalSubmitEntityErrors';
import { useDispatch, useSelector } from 'react-redux';
import {
  cloneSurvey,
  createNewSurvey,
  renameSurvey,
  selectActiveAccount,
  selectAllAccounts,
  selectSelectedAccount,
  submitCurrentEntity,
  deleteSurvey,
  adduserToAccount,
  createNewEntity,
  selectUser,
  refuseEntity,
} from '../../reducers/account.reducer';
import { ModalNextUnfulfilledSurvey } from '../modal-next-unfulfilled-survey/ModalNextUnfulfilledSurvey';
import { paths } from '@routes/routes.constants';
import { useHistory } from 'react-router-dom';
import { addEventTracking, filterSharedEntities, filterSharedSteps, getAuthorizedSurveys, isEntityConformityOK } from '../../utils/utils';
import { ModalNewProduct } from '../modal-new-product/ModalNewProduct';
import { selectAllContentfulData } from '../../reducers/contentful.reducer';
import { ModalError } from '../modal-error/ModalError';
import { ModalDuplicateProduct } from '../modal-duplicate-product/ModalDuplicateProduct';
import { ModalRenameProduct } from '../modal-rename-product/ModalRenameProduct';
import { ModalDeleteProduct } from '../modal-delete-product/ModalDeleteProduct';
import { ModalAddUser } from '../modal-add-user/ModalAddUser';
import ModalAddEntity from '@components/modal-add-entity/ModalAddEntity';
import { toast } from 'react-toastify';
import ModalShare from '@components/modal-share/ModalShare';

export const ModalWrapper = ({ setLoginMode }) => {
  const dispatch = useDispatch<any>();
  const modal = useSelector(selectModal);
  const allAccounts = useSelector(selectAllAccounts);
  const activeAccount = useSelector(selectActiveAccount);
  const selectedAccount = useSelector(selectSelectedAccount);
  const currentEntity = useSelector(selectCurrentEntity);
  const currentSurvey = useSelector(selectCurrentSurvey);
  const currentVersion = useSelector(selectCurrentVersion);
  const shareModal = useSelector(selectShareModal);
  const history = useHistory();
  const contentfulData = useSelector(selectAllContentfulData);
  const errors = useSelector(selectErrors);
  const currentUser = useSelector(selectUser);

  const authorizedSurveys = getAuthorizedSurveys();

  const MODAL_COMPONENTS = {
    [MODAL_TYPES.ADD_USER]: ModalAddUser,
    [MODAL_TYPES.DELETE_PRODUCT]: ModalDeleteProduct,
    [MODAL_TYPES.DUPLICATE_PRODUCT]: ModalDuplicateProduct,
    [MODAL_TYPES.ERROR]: ModalError,
    [MODAL_TYPES.NEXT_UNFULFILLED_SURVEY]: ModalNextUnfulfilledSurvey,
    [MODAL_TYPES.NEW_PRODUCT]: ModalNewProduct,
    [MODAL_TYPES.ADD_NEW_ENTITY]: ModalAddEntity,
    [MODAL_TYPES.RENAME_PRODUCT]: ModalRenameProduct,
    [MODAL_TYPES.SUBMIT_ENTITY]: ModalSubmitEntity,
    [MODAL_TYPES.SUBMIT_ENTITY_CONFIRMATION]: ModalSubmitEntityConfirmation,
    [MODAL_TYPES.SUBMIT_ENTITY_ERRORS]: ModalSubmitEntityErrors,
    [MODAL_TYPES.SHARE]: ModalShare,
  };

  const onAddProduct = () => {
    dispatch(setModal({ show: true, type: MODAL_TYPES.NEW_PRODUCT }));
  };

  const onClick = modalType => {
    switch (modalType) {
      case MODAL_TYPES.DELETE_PRODUCT:
        return handleDeleteSurvey();
      case MODAL_TYPES.NEXT_UNFULFILLED_SURVEY:
        return goToNextUnfulfilledSurveyVersion();
      case MODAL_TYPES.SUBMIT_ENTITY:
        return handleSubmitEntity();
      case MODAL_TYPES.SUBMIT_ENTITY_ERRORS:
      case MODAL_TYPES.SUBMIT_ENTITY_CONFIRMATION:
        return submitEntity();
      case MODAL_TYPES.ERROR:
        dispatch(setErrors(null));
        return;
    }
  };

  const onClose = () => {
    if (errors) {
      dispatch(setErrors(null));
    }

    dispatch(setModal({ show: false, type: null }));
  };

  const onLoading = () => {};

  const hasProduct = () => {
    let versionableSurveys = authorizedSurveys?.filter(survey => survey.versioning.enabled);
    if (versionableSurveys.length === 0 || !currentEntity) return false;
    let responses = [];
    versionableSurveys?.map(surveyItem => {
      responses.push(
        filterSharedEntities(activeAccount.entities)
          .find(entityItem => entityItem.name === currentEntity.name)
          ?.responses.find(survey => survey.surveyId === surveyItem.id)
      );
    });
    let isAllEmpty = responses.every(response => !response?.versions?.length);
    return responses.length !== 0 && !isAllEmpty;
  };

  const addUserToSelectedAccount = async newUser => {
    let newUserObject = {
      accountOwner: true,
      activity: {
        lastSaved: null,
        sentMails: [],
      },
      firstName: newUser.firstName,
      name: newUser.lastName,
      email: newUser.email,
      receivesEmails: true,
      wizardCompleted: true,
      role: 'admin',
    };
    dispatch(adduserToAccount({ accountId: selectedAccount ? selectedAccount._id : activeAccount._id, user: newUserObject, sendMail: true }));
    dispatch(setModal({ show: false, type: null }));
  };

  const handleSubmitEntity = async () => {
    if (await isEntityConformityOK(currentEntity)) {
      addEventTracking('Entity', 'Submit entity', 'Via modal');
      submitEntity();
    } else {
      dispatch(setModal({ show: true, type: MODAL_TYPES.SUBMIT_ENTITY_ERRORS }));
    }
  };

  const handleChangeLoginMode = loginMode => {
    setLoginMode(loginMode);
    dispatch(setErrors(null));
  };

  const handleRenameSurvey = async (newProductName: string) => {
    let currentEntityCopy = activeAccount?.entities.find(entityItem => entityItem.name === currentEntity.name);
    let currentSurveyCopy = currentEntityCopy.responses.find(surveyItem => surveyItem.surveyId === currentSurvey.id);
    dispatch(
      renameSurvey({ entityId: currentEntity._id, surveyId: currentSurveyCopy._id, versionId: currentVersion._id, newName: newProductName?.trim() })
    );
    dispatch(setModal({ show: false, type: null }));
  };

  const handleCloneSurvey = async (newProductName: string) => {
    dispatch(setLoading(true));
    addEventTracking('Survey', 'Add new service', 'Via duplicate');
    dispatch(
      cloneSurvey({
        accountId: activeAccount._id,
        entityId: currentEntity._id,
        surveyId: currentSurvey.id,
        versionId: currentVersion._id,
        name: newProductName?.trim(),
      })
    );
    dispatch(setModal({ show: false, type: null }));
    dispatch(setLoading(false));
  };

  const handleDeleteSurvey = async () => {
    dispatch(deleteSurvey({ versionId: currentVersion._id, surveyId: currentSurvey.id }));
    dispatch(setModal({ show: false, type: null }));
  };

  const submitEntity = async () => {
    dispatch(submitCurrentEntity({ accountId: activeAccount._id, entityId: currentEntity._id }));
    dispatch(setModal({ show: false, type: null }));
    toast.success("L'entité a bien été soumise");
    history.push(paths.dashboardGroup);
  };

  const getNewProductOptions = () => {
    return (
      contentfulData?.surveys
        ?.filter(survey => survey.versioning.enabled)
        ?.filter(survey => activeAccount?.actionRights?.surveys?.some(authorizedSurvey => authorizedSurvey.id === survey.id))
        ?.map(survey => ({ label: survey.name, value: survey, id: survey.id })) || []
    );
  };

  const isNewProductNameValid = (productName: string, productType: any) => {
    if (!productName || !productType) return false;
    let alreadyExists = activeAccount.entities
      .find(entity => entity.name === currentEntity.name)
      .responses.find(survey => survey.surveyId === productType.id)
      .versions.some(surveyVersion => surveyVersion.versionName === productName?.trim());
    return productName?.trim().length !== 0 && !alreadyExists;
  };

  const isEmailAlreadyUsed = email => {
    let emailFound = false;
    allAccounts.forEach(account => {
      if (account.users.some(user => user.email === email)) {
        emailFound = true;
      }
    });
    return emailFound;
  };

  const addNewProduct = async (productName: string, productType: any) => {
    if (!productName || !productType) return;
    dispatch(setLoading(true));
    addEventTracking('Survey', 'Add new service', 'Via button');
    dispatch(createNewSurvey({ accountId: activeAccount._id, entityId: currentEntity._id, surveyId: productType.id, name: productName }));
    if (currentEntity.submission?.submitted) {
      await dispatch(
        refuseEntity({
          adminId: activeAccount._id,
          accountId: activeAccount._id,
          entityId: currentEntity._id,
          reason: 'un nouveau service a été ajouté',
        })
      );
    }

    dispatch(setModal({ show: false, type: null }));
    dispatch(setLoading(false));
  };

  const handleAddEntity = async newEntityName => {
    dispatch(setLoading(true));
    addEventTracking('Entity', 'Add new entity', 'Via button');
    dispatch(createNewEntity({ accountId: activeAccount?._id, userId: currentUser._id, name: newEntityName?.trim() }));
    dispatch(setModal({ show: false, type: null }));
    dispatch(setLoading(false));
  };

  const isValidEntityName = newEntityName => {
    let alreadyExists = activeAccount?.entities.some(entity => entity.name === newEntityName?.trim());
    return !!newEntityName && newEntityName?.trim().length !== 0 && !alreadyExists;
  };

  const goToNextUnfulfilledSurveyVersion = () => {
    let unfulfilledSurveys = authorizedSurveys
      .map(survey => {
        if (!survey.versioning.enabled) {
          let version = currentEntity.responses.find(entityResponse => entityResponse.surveyId === survey.id).versions[0];
          let isUnfulfilled = version?.completion?.completionPercentage !== 100;
          if (isUnfulfilled) return { survey, version };
        } else {
          return currentEntity.responses
            .find(entityResponse => entityResponse.surveyId === survey.id)
            .versions.map(version => {
              if (!version.versionResponses) return { survey, version };
              let isUnfulfilled = version?.completion?.completionPercentage !== 100;
              if (isUnfulfilled) return { survey, version };
            });
        }
      })
      .flat()
      .filter(Boolean);

    //TODO check if need to save response before redirect
    if (unfulfilledSurveys?.length >= 1) {
      dispatch(setCurrentSurvey(unfulfilledSurveys[0].survey));
      dispatch(setCurrentVersion(unfulfilledSurveys[0].version));
      let targetedSteps = filterSharedSteps(unfulfilledSurveys[0].survey.steps);
      dispatch(setCurrentStep({ id: targetedSteps[0].id, name: targetedSteps[0].name }));
      history.push(paths.survey);
    }
    dispatch(setModal({ show: false, type: null }));
  };

  const getModalProps = ModalType => ({
    loading: () => onLoading(),
    show: modal.show || !!errors,
    onClose: () => onClose(),
    onClick: () => onClick(modal.type || MODAL_TYPES.ERROR),
    ...(ModalType === MODAL_TYPES.ADD_USER && {
      addUser: user => addUserToSelectedAccount(user),
      isEmailAlreadyUsed: email => isEmailAlreadyUsed(email),
    }),
    ...(ModalType === MODAL_TYPES.DUPLICATE_PRODUCT && {
      onCloneSurvey: productName => handleCloneSurvey(productName),
      isNewProductNameValid: (productName, productType) => isNewProductNameValid(productName, productType),
      duplicatedProduct: { survey: currentSurvey, version: currentVersion },
    }),
    ...(ModalType === MODAL_TYPES.RENAME_PRODUCT && {
      onRenameSurvey: productName => handleRenameSurvey(productName),
      isNewProductNameValid: (productName, productType) => isNewProductNameValid(productName, productType),
      renamedProduct: { survey: currentSurvey, version: currentVersion },
    }),
    ...(ModalType === MODAL_TYPES.DELETE_PRODUCT && {
      deletedProduct: { survey: currentSurvey, version: currentVersion },
    }),
    ...(ModalType === MODAL_TYPES.NEXT_UNFULFILLED_SURVEY && {
      entity: currentEntity,
    }),
    ...(ModalType === MODAL_TYPES.NEW_PRODUCT && {
      addNewProduct: (productName, productType) => addNewProduct(productName, productType),
      productOptions: getNewProductOptions(),
      isNewProductNameValid: (productName, productType) => isNewProductNameValid(productName, productType),
    }),
    ...(ModalType === MODAL_TYPES.SUBMIT_ENTITY && {
      entity: currentEntity,
      onSubmit: () => handleSubmitEntity(),
      onAddProduct: () => onAddProduct(),
      hasProduct: hasProduct(),
    }),
    ...(ModalType === MODAL_TYPES.SUBMIT_ENTITY_ERRORS && {
      entity: currentEntity,
    }),
    ...(ModalType === MODAL_TYPES.SUBMIT_ENTITY_CONFIRMATION && {
      entity: currentEntity,
    }),
    ...(ModalType === MODAL_TYPES.ERROR && {
      errors,
      onChangeLoginMode: loginMode => handleChangeLoginMode(loginMode),
    }),
    ...(ModalType === MODAL_TYPES.SHARE && {
      ...shareModal,
    }),
    ...(ModalType === MODAL_TYPES.ADD_NEW_ENTITY && {
      addEntity: newEntityName => handleAddEntity(newEntityName),
      isValidEntityName: newEntityName => isValidEntityName(newEntityName),
    }),
  });

  const renderModalComponent = () => {
    const ModalType = modal.type ?? MODAL_TYPES.ERROR;
    const ModalComponent = MODAL_COMPONENTS[ModalType];
    return <ModalComponent {...getModalProps(ModalType)} />;
  };

  return <>{(modal.show || !!errors) && renderModalComponent()}</>;
};
