import styled, { css } from 'styled-components';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AvatarGroup, Chip, Stack, Select as MUISelect } from '@mui/material';
import { InputComponent } from '@components/form/InputComponent';

import { IoChatbubblesOutline } from 'react-icons/io5';

import { COLORS } from 'src/utils/constants';

export const NoDataContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
`;

export const LinkField = styled.div<{ type: string }>`
  display: flex;
  align-items: center;
  column-gap: 10px;

  svg.add {
    flex: 1;
    height: 30px;
    width: 30px;
    color: ${COLORS.DarkGrey};
    transition: opacity 0.2s ease-in-out;
    opacity: 0.9;

    &:hover {
      opacity: 1;
    }
  }

  svg:not(.add):not(.erase) {
    height: 30px;
    width: 30px;
    // flex: 1;
    color: ${({ type }) => {
      let color = 'transparent';
      switch (type) {
        case 'docx':
          color = COLORS.docx;
          break;
        case 'xlsx':
          color = COLORS.xlsx;
          break;
        case 'pdf':
          color = COLORS.pdf;
          break;
        default:
          color = COLORS.DarkGreen;
          break;
      }
      return color;
    }};
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  svg.erase {
    transition: opacity 0.2s ease-in-out;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }
`;

export const ErasePlaceholder = styled.div`
  width: 25px;
`;

export const HeaderActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  & > :not(:last-child) {
    margin-right: 1rem;
  }
`;

export const RowContainer = styled.div`
  &:hover {
    background-color: #80808012;
    border-radius: 0.625rem;
    padding: 0.5rem;
    cursor: pointer;
  }
`;

export const AdminRowContainer = styled.div<{ backlog?: boolean }>`
  opacity: ${({ backlog }) => (backlog ? '50%' : '100%')};
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Div = styled.div<{ center?: boolean; bolder?: boolean }>`
  display: flex;
  justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
  text-align: ${({ center }) => center && 'center'};
  font-weight: ${({ bolder }) => (bolder ? 'bold' : 'unset')};
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  font-size: 1.2rem;
  padding: 1.5rem;

  overflow: auto;

  span {
    font-weight: bold;

    &.status {
      color: #f5a623;
    }
  }

  button {
    font-size: 1em;
  }

  @media (max-height: 800px) {
    padding-top: 13rem;
  }
`;

export const AdjustActions = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  // column-gap: 20px;
`;

export const ResponsibleContainer = styled.div`
  display: grid;
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const ResponsibleItem = styled.div`
  display: flex;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 1rem;
  }
  svg {
    cursor: pointer;
    margin-left: 0;
  }
`;

export const ProofFilesContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > :not(:last-child) {
    margin-right: 1rem;
  }
  svg {
    margin-left: 0;
  }
`;
export const Tag = styled.div<{ status?: string; urgency?: string }>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  //padding: 0.725rem 1rem;
  border-radius: 10px;
  width: fit-content;
  svg {
    margin-right: 1rem;
  }

  ${({ status }) => {
    switch (status) {
      case 'pending':
        return `
          color:#7f7f7f;
          // background-color: #dedede85;
          // border: 0.5px solid #7f7f7f;
        `;
      case 'ongoing':
        return `
          color:#ff8157;
          // background-color: #ffaa3754;
          // border: 0.5px solid #ff8157;
        `;
      case 'completed':
        return `
          color:#28a745;
          // background-color: #87d37c30;
          // border: 0.5px solid #87d37c;
        `;
      default:
        break;
    }
  }}

  ${({ urgency }) => {
    switch (urgency) {
      case 'low':
        return `color:#0091ff;`;
      case 'medium':
        return `color:#d4af37;`;
      case 'high':
        return `color:#ef3e36;`;
      default:
        break;
    }
  }}
`;

export const DivContainer = styled.div<{ center?: boolean; editable?: boolean; size?: string; height?: boolean; bolder?: boolean }>`
  display: grid;
  height: ${({ height }) => (height ? '4rem' : 'unset')};
  align-items: center;
  grid-template-columns: 15% 84%;
  column-gap: 1%;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
  align-items: ${({ center }) => (center ? 'center' : 'unset')};
  font-weight: ${({ bolder }) => (bolder ? 'bold' : 'unset')};
  color: grey;
  span {
    color: black;
    margin-right: 1rem;
    font-weight: bold;
  }
  .justify-text {
    text-align: justify;
  }

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: 1.2rem;
        `;
      default:
        return null;
    }
  }}
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const AvatarContainer = styled(AvatarGroup)`
  justify-content: flex-end;
`;

export const CustomDatePicker = styled(DatePicker)<{ editable: boolean }>`
  input,
  fieldset {
    border: none;
  }
  input {
    padding-left: 2rem;
    padding-top: 0;
    padding-bottom: 0;
    color: grey;
  }
  .MuiInputBase-root {
    flex-direction: row-reverse;
    font-size: ${({ editable }) => editable && '1.2rem !important'};
  }
  .MuiIconButton-root {
    padding: 0;
  }
  .MuiInputAdornment-root {
    margin-left: 0;
  }
`;

export const CustomChip = styled(Chip)<{ urgency?: string }>`
  height: 25px !important;
  font-size: 1rem !important;
  padding: 0.5rem !important;
  ${({ urgency }) => {
    switch (urgency) {
      case 'low':
        return css`
          background-color: #7bdfc833 !important;
          color: #7bdfc8 !important;
          border: 1px solid #7bdfc8 !important;
        `;
      case 'medium':
        return css`
          background-color: #f49d1d33 !important;
          color: #f49d1d !important;
          border: 1px solid #f49d1d !important;
        `;
      case 'high':
        return css`
          background-color: #f97a8233 !important;
          color: #f97a82 !important;
          border: 1px solid #f97a82 !important;
        `;
      default:
        return null;
    }
  }}
`;

// export const CustomSelect = styled(MUISelect)`
//   width: 100%;
//   max-width: 15%;
//   font-size: 1.2rem !important;
//   padding: 0;
//   input,
//   fieldset {
//     border: none;
//   }
//   .MuiSelect-select {
//     padding-left: 0;
//     color: grey;
//   }
//   .MuiPickersPopper-root {
//     z-index: 9999;
//   }
// `;

export const CustomStack = styled(Stack)`
  font-size: 2rem;
  padding-left: 15%;
`;

export const DataTableContainer = styled.div<{ admin?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;

  @media only screen and (min-width: 1224px) {
    max-height: 45vh;
    ${({ admin }) =>
      admin &&
      css`
        max-height: 55vh;
      `}
  }

  @media only screen and (min-width: 1824px) {
    max-height: 60vh;
    ${({ admin }) =>
      admin &&
      css`
        max-height: 65vh;
      `}
  }

  .data-table {
    overflow: auto;

    .header {
      /* styles for the header */
    }

    .row {
      align-items: center;
      column-gap: 0.75%;
      padding: 0.5rem;
    }
    .row:not(last-child) {
      margin-bottom: 0.5rem;
    }
    .row:not(first-child) {
      margin-top: 0.5rem;
    }
  }
`;

export const VerticalLine = styled.div`
  content: '';
  position: absolute;
  top: 50%;
  width: 92%;
  left: 5%;
  height: 1px;
  background-color: #e74c3c;
  transform: translateY(-50%);
`;

// ------------- Modal Subcontractor -----------------
export const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: center;
  row-gap: 26px;

  p {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Roboto', serif;
    line-height: 1.5em;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;

export const Badge = styled.div<{ variant?: string }>`
  width: fit-content;
  height: fit-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 0.875rem;
  border-radius: 0.625rem;
  font-weight: 700;
  transition: 0.3s all;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  padding: 0.75rem;
  margin-right: 1rem;
  ${({ variant }) => {
    return variant === 'button' && 'cursor : pointer;';
  }};
`;

export const Infos = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  svg {
    color: ${COLORS.Squash};
    animation: swing 0.8s infinite ease-in-out;
  }
`;

export const Tab = styled.div<{ selected: boolean }>`
  padding: 20px 15px;
  height: calc(100% - 40px);
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    `
      background-color: #326C82;
      color: #fff;     
  `}
  &:first-child {
    border-top-left-radius: 4px;
    border-right: 1px solid ${COLORS.MediumGrey};
  }
  &:last-child {
    border-top-right-radius: 4px;
  }
  &:hover {
    background-color: #326c82;
    color: #fff;
  }
`;

export const TabsContainer = styled.div`
  width: 100%;
  font-weight: bold;
  display: flex;
`;

export const ChatBubbleIcon = styled(IoChatbubblesOutline)<{ active: boolean }>`
  ${({ active }) =>
    !active &&
    `
    opacity: 0.5;
    `}
`;

export const FloatingActionBar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 0.625rem;
  background-color: #eff0f6eb;
  padding: 1rem;
  margin-top: 0.5rem;

  position: fixed;
  bottom: 1%;
  left: 40%;
  width: fit-content;

  & > * {
    margin: 0;
  }

  & > :not(:last-child) {
    margin-right: 2rem;
  }

  animation: slideUp 0.5s ease-in-out;
`;

export const CheckedElements = styled.span`
  font-size: 1.1em; // Font size of the text
  font-weight: bold; // Font weight (bold) of the text
`;

export const StyledInputComponent = styled(InputComponent)`
  margin-left: 0.1rem;
  .input input {
    height: 100% !important;
  }
`;

export const CustomSelect = styled(MUISelect)`
  width: 100%;
  max-width: 15%;
  font-size: 1.2rem !important;
  padding: 0;
  input,
  fieldset {
    border: none;
  }
  .MuiSelect-select {
    padding-left: 0;
    color: grey;
  }
  .MuiPickersPopper-root {
    z-index: 9999;
  }
`;

export const Link = styled.a``;

export const ConfirmContainer = styled.div`
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  font-size: 1.5em;
  padding: 20px;

  div {
    text-align: center;

    span {
      font-weight: 600;

      color: #187ead;

      &.status {
        color: #f5a623;
      }
    }
  }

  button {
    font-size: 1em;
  }
`;
