import styled from 'styled-components';

import { actionsStatus } from '../ActionPlanDash.constants';
import { useTranslation } from 'react-i18next';

const ActionStatus = ({ status, justifyContent = 'center', onlyIcon = false }) => {
  const { t } = useTranslation();
  const actionStatus = actionsStatus[status];

  if (!actionStatus) {
    return (
      <ActionStatusContainer justifyContent={justifyContent}>
        <ActionStatusTitle color='#000'>{t('common.not_activated')}</ActionStatusTitle>
      </ActionStatusContainer>
    );
  }

  const { title = t('common.not_activated'), icon: Icon, color } = actionStatus;

  return (
    <ActionStatusContainer justifyContent={justifyContent}>
      {Icon && <Icon size={24} color={color} />}
      {!onlyIcon && <ActionStatusTitle color={color}>{title}</ActionStatusTitle>}
    </ActionStatusContainer>
  );
};

export default ActionStatus;

const ActionStatusContainer = styled.div<{ justifyContent?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  & > :not(:last-child) {
    margin-right: 0.5rem;
  }
`;

const ActionStatusTitle = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;
