import { Button } from '../Button';
import { HiPlus } from 'react-icons/hi';
import { MdLockOutline, MdEditNote } from 'react-icons/md';
import { RiShareForwardLine } from 'react-icons/ri';
import { deleteEntity, duplicateEntity, renameEntity, selectActiveAccount, selectUser } from '../../reducers/account.reducer';
import { useDispatch, useSelector } from 'react-redux';
/*import { Chips } from '../chips/Chips';*/
import { Pie } from '../pie/Pie';
import { COLORS, MODAL_TYPES } from '../../utils/constants';
import {
  BasicModalContainer,
  ButtonContainer,
  Data,
  EntityTableContainer,
  MoreOptionsContainer,
  Name,
  PieContainer,
  Row,
  RowsContainer,
  RowsSlider,
  Splitter,
  Top,
} from './EntityTable_.style';

import {
  getEntityConformityPercentage,
  getAuthorizedSurveys,
  filterSharedEntities,
  totalSurveyCount,
  /*getEntityTotalFillingPercentage,*/
  addEventTracking,
  isSurveyDisabled,
} from '../../utils/utils';
import { MoreOptions } from '../more-option/MoreOption';
import { Modal } from '../modal/Modal';
import { InputComponent } from '../form/InputComponent';
import { setCurrentEntity, setCurrentSurvey, setModal, setShareModal } from '../../reducers/app.reducer';
import { useEffect, useRef, useState } from 'react';
import { paths } from '@routes/routes.constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const EntityTable = () => {
  const { t } = useTranslation();
  const [newEntityName, setNewName] = useState(null);
  const [displayRenameEntityModal, setDisplayRenameEntityModal] = useState({
    show: false,
    entity: null,
  });
  const [displayDuplicateEntityModal, setDisplayDuplicateEntityModal] = useState({
    show: false,
    entity: null,
  });
  const [displayDeleteEntityModal, setDisplayDeleteEntityModal] = useState({
    show: false,
    entity: null,
  });

  //REDUX
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const activeAccount = useSelector(selectActiveAccount);
  const currentUser = useSelector(selectUser);

  const timerRef = useRef(null);

  const handleShowRenameEntityModal = entity => {
    setDisplayRenameEntityModal({
      show: true,
      entity: entity,
    });
  };

  const handleShowDuplicateEntityModal = entity => {
    setDisplayDuplicateEntityModal({
      show: true,
      entity: entity,
    });
  };

  const handleShowDeleteEntityModal = entity => {
    setDisplayDeleteEntityModal({
      show: true,
      entity: entity,
    });
  };

  const closeDeleteEntityModal = () => {
    decomposedClose(setDisplayDeleteEntityModal, displayDeleteEntityModal);
  };

  const closeRenameEntityModal = () => {
    setNewName(null);
    decomposedClose(setDisplayRenameEntityModal, displayRenameEntityModal);
  };

  const closeDuplicateEntityModal = () => {
    setNewName(null);
    decomposedClose(setDisplayDuplicateEntityModal, displayDuplicateEntityModal);
  };

  const decomposedClose = (setter, values) => {
    setNewName(null);
    setter({
      ...values,
      show: false,
    });
    const timer = setTimeout(() => {
      setter({
        show: false,
        entity: null,
      });
    }, 500);
    timerRef.current = timer;
  };

  const handleCloneEntity = async () => {
    addEventTracking('Entity', 'Add new entity', 'Via duplicate');
    dispatch(
      duplicateEntity({ accountId: activeAccount?._id, entityId: displayDuplicateEntityModal?.entity?._id, entityName: newEntityName?.trim() })
    );
    closeDuplicateEntityModal();
  };

  const handleDeleteEntity = async (entity: any) => {
    addEventTracking('Entity', 'Delete entity');
    dispatch(deleteEntity({ accountId: activeAccount?._id, entityId: entity?._id }));
    closeDeleteEntityModal();
  };

  const handleEntityChangeName = async () => {
    addEventTracking('Entity', 'Rename entity');
    dispatch(renameEntity({ accountId: activeAccount?._id, entityId: displayRenameEntityModal.entity._id, entityName: newEntityName?.trim() }));
    closeRenameEntityModal();
  };

  const getEntityOptions = entity => [
    ...(entity?.submission?.submitted !== true && currentUser.role !== 'guest'
      ? [
          {
            label: t('entityTable.entityActions.share'),
            onClick: () => shareItem(entity),
            icon: RiShareForwardLine,
          },
          {
            label: t('entityTable.entityActions.duplicate'),
            onClick: () => handleShowDuplicateEntityModal(entity),
          },
          {
            label: t('entityTable.entityActions.rename'),
            onClick: () => handleShowRenameEntityModal(entity),
          },
          {
            label: t('entityTable.entityActions.submit'),
            onClick: () => handleSubmitEntity(activeAccount._id, entity),
          },
          {
            label: t('entityTable.entityActions.delete'),
            onClick: () => handleShowDeleteEntityModal(entity),
            color: '#ed3f3e',
          },
        ]
      : []),
  ];

  const handleSubmitEntity = async (accountId: string, entity: any) => {
    dispatch(setCurrentEntity(entity));
    addEventTracking('Entity', 'Submit entity', 'Via dashboard group');
    dispatch(setModal({ show: true, type: MODAL_TYPES.SUBMIT_ENTITY_CONFIRMATION }));
  };

  const shareItem = entity => {
    dispatch(setCurrentEntity(entity));
    dispatch(setShareModal({ type: 'entities', id: entity._id, label: entity.name }));
    dispatch(setModal({ show: true, type: MODAL_TYPES.SHARE }));
  };

  const handleEntityClick = async entity => {
    dispatch(setCurrentEntity(entity));
    let authorizedSurveys = getAuthorizedSurveys();
    let activatedAuthorizedSurveys = authorizedSurveys.filter(survey => !isSurveyDisabled(survey.id, false));
    dispatch(setCurrentSurvey(activatedAuthorizedSurveys?.[0]));
    history.push(paths.dashboardEntity);
  };

  const isValidEntityName = () => {
    let alreadyExists = activeAccount?.entities.some(entity => entity.name === newEntityName?.trim());
    return !!newEntityName && newEntityName?.trim().length !== 0 && !alreadyExists;
  };

  /*const getChipsLabel = async entity => {
    let entityFillingPercentage = await getEntityTotalFillingPercentage(entity);
    let { submitted, rejected, validated } = entity?.submission;
    if (submitted) {
      return { color: COLORS.Squash, label: t('common.beingVerfied') };
    }
    if (rejected) {
      return { color: `#fab1a0`, label: t('common.rejected') };
    }
    if (validated) {
      return { color: COLORS.AquaMarine, label: t('common.validated') };
    }
    if (entityFillingPercentage === 0) {
      return { color: COLORS.DarkGreen, label: t('common.toFill') };
    }
    if (entityFillingPercentage > 0) {
      return { color: COLORS.lightNavy, label: t('entityTable.entityChipsLabels.inProgress') };
    }
  };*/

  useEffect(() => {
    const timeoutId = timerRef.current;
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      <EntityTableContainer>
        <Top>
          <span>{t('common.myEntities')}</span>
          {currentUser?.role !== 'guest' && (
            <Button
              onClick={() => dispatch(setModal({ show: true, type: MODAL_TYPES.ADD_NEW_ENTITY }))}
              icon={HiPlus}
              type={'light'}
              label={t('entityTable.entityLabels.addEntity')}
            />
          )}
        </Top>
        <RowsSlider>
          <RowsContainer>
            {activeAccount &&
              filterSharedEntities(activeAccount?.entities)?.map((entity, index) => {
                let isSubmitted = entity?.submission?.submitted === true;
                return (
                  <Row key={index} onClick={() => handleEntityClick(entity)}>
                    <Name>
                      {isSubmitted && <MdLockOutline />}
                      {entity.name}
                    </Name>
                    {/*<Chips {...getChipsLabel(entity)} />*/}
                    <Splitter />
                    <Data className={'completed-surveys'}>
                      <MdEditNote />
                      {totalSurveyCount(entity).completedSurveysCount === 0 ? (
                        <span>{t('entityTable.entityLabels.accessSurveys')}</span>
                      ) : (
                        <span>
                          {totalSurveyCount(entity).completedSurveysCount} / {totalSurveyCount(entity).totalSurveysCount}{' '}
                          {t('entityTable.entityLabels.completedSurveys')}
                        </span>
                      )}
                    </Data>
                    <PieContainer>
                      <Pie largePercent={true} inverted={true} color={COLORS.AquaMarine} percent={getEntityConformityPercentage(entity)} />
                    </PieContainer>
                    <MoreOptionsContainer>
                      <MoreOptions options={getEntityOptions(entity)} />
                    </MoreOptionsContainer>
                  </Row>
                );
              })}
          </RowsContainer>
        </RowsSlider>
      </EntityTableContainer>
      <Modal
        alignment={'center'}
        size={{ width: '50%' }}
        show={displayDeleteEntityModal?.show}
        title={t('entityTable.modalTitles.confirmDelete', { entityName: displayDeleteEntityModal?.entity?.name })}
        onClose={closeDeleteEntityModal}>
        <BasicModalContainer>
          <p>{t('entityTable.messages.irreversibleAction')}</p>
          <ButtonContainer>
            <Button type={'danger'} onClick={() => handleDeleteEntity(displayDeleteEntityModal?.entity)} label={t('common.delete')} />
            <Button type={'light'} onClick={closeDeleteEntityModal} label={t('common.cancel')} />
          </ButtonContainer>
        </BasicModalContainer>
      </Modal>
      <Modal
        alignment={'center'}
        size={{ width: '50%' }}
        show={displayRenameEntityModal?.show}
        title={t('entityTable.modalTitles.renameEntity', { entityName: displayRenameEntityModal?.entity?.name })}
        onClose={closeRenameEntityModal}>
        <BasicModalContainer>
          <InputComponent
            focus={true}
            placeholder={t('entityTable.placeholders.newName')}
            sharable={false}
            label={t('entityTable.labels.newEntityName')}
            type={'text'}
            onChange={e => setNewName(e.target.value)}
            error={activeAccount?.entities.some(entity => entity.name === newEntityName?.trim()) && t('common.nameUsed')}
            value={newEntityName}
          />
          <ButtonContainer>
            <Button onClick={handleEntityChangeName} label={t('common.validate')} disabled={!isValidEntityName()} />
            <Button type={'light'} onClick={closeRenameEntityModal} label={t('common.cancel')} />
          </ButtonContainer>
        </BasicModalContainer>
      </Modal>
      <Modal
        alignment={'center'}
        size={{ width: '50%' }}
        show={displayDuplicateEntityModal?.show}
        title={t('entityTable.modalTitles.copyEntity', { entityName: displayDuplicateEntityModal?.entity?.name })}
        onClose={closeDuplicateEntityModal}>
        <BasicModalContainer>
          <InputComponent
            focus={true}
            placeholder={t('entityTable.placeholders.newName')}
            sharable={false}
            label={t('entityTable.labels.copyName')}
            type={'text'}
            onChange={e => setNewName(e.target.value)}
            error={activeAccount?.entities.some(entity => entity.name === newEntityName?.trim()) && t('common.nameUsed')}
            value={newEntityName}
          />
          <ButtonContainer>
            <Button onClick={handleCloneEntity} label={t('common.validate')} disabled={!isValidEntityName()} />
            <Button type={'light'} onClick={closeDuplicateEntityModal} label={t('common.cancel')} />
          </ButtonContainer>
        </BasicModalContainer>
      </Modal>
    </>
  );
};
