import { Modal } from '../modal/Modal';

import styled from 'styled-components';
import { Button } from '../Button';
import { InputComponent } from '../form/InputComponent';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface ModalNewProductProps {
  show?: boolean;
  onClose: () => void;
  onClick: () => void;
  addNewProduct: (productName: string, productType: any) => void;
  productOptions: [];
  isNewProductNameValid: (productName: string, productType: any) => boolean;
}

export const ModalNewProduct = ({ show, onClose, onClick, productOptions, addNewProduct, isNewProductNameValid }: ModalNewProductProps) => {
  const { t } = useTranslation();
  const [newProductName, setNewProductName] = useState(null);
  const [newProductType, setNewProductType] = useState(null);

  const handleNewSurveyTypeChange = (name, value) => {
    setNewProductType(value);
  };

  return (
    <>
      {show && (
        <Modal alignment={'center'} size={{ width: '50%' }} show={show} title={t('modal.new_product_creation')} onClose={onClose}>
          <BasicModalContainer>
            <InputComponent
              onChange={handleNewSurveyTypeChange}
              sharable={false}
              type={'select'}
              label={t('modal.select_service_type')}
              options={productOptions}
            />
            <InputComponent
              focus={false}
              placeholder={t('modal.new_name_placeholder')}
              sharable={false}
              label={t('modal.new_service_name')}
              type={'text'}
              onChange={e => setNewProductName(e.target.value)}
              error={
                newProductName &&
                newProductType &&
                newProductName?.trim().length !== 0 &&
                !isNewProductNameValid(newProductName, newProductType) &&
                t('modal.name_already_used_error')
              }
              value={newProductName}
            />
            <ButtonContainer>
              <Button
                disabled={!isNewProductNameValid(newProductName, newProductType)}
                onClick={() => addNewProduct(newProductName, newProductType)}
                label={t('modal.create_service_button')}
              />
              <Button type={'light'} onClick={onClose} label={t('common.cancel')} />
            </ButtonContainer>
          </BasicModalContainer>
        </Modal>
      )}
    </>
  );
};

const BasicModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  align-items: center;
  row-gap: 26px;

  p {
    font-size: 1.3em;
    font-weight: 400;
    font-family: 'Roboto', serif;
    line-height: 1.5em;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 20px;
  align-items: center;
`;
