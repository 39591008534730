import { Modal } from '@components/modal/Modal';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveAccount, selectUser, shareItem } from '../../reducers/account.reducer';
import { selectCurrentEntity, selectCurrentStep, selectCurrentSurvey, selectCurrentVersion } from '../../reducers/app.reducer';
import { addEventTracking, getActionByShareId } from '../../utils/utils';
import { toast } from 'react-toastify';
import ModalShareListUser from './ModalShareListUser';
import { ModalShareProps } from './ModalShare.type';

const ModalShare = ({ type, id, targetSurveyId, relatedProductId, label, onClose, loading, show }: ModalShareProps) => {
  const { t } = useTranslation();
  // REDUX
  const dispatch = useDispatch<any>();
  const activeAccount = useSelector(selectActiveAccount);
  const currentEntity = useSelector(selectCurrentEntity);
  const currentSurvey = useSelector(selectCurrentSurvey);
  const currentVersion = useSelector(selectCurrentVersion);
  const currentStep = useSelector(selectCurrentStep);
  const host = useSelector(selectUser);
  // STATE
  const [usersChecked, setUsersChecked] = useState([]);
  const [emailInputList, setEmailInputList] = useState([]);
  const [commentInput, setCommentInput] = useState('');

  const handleShare = async () => {
    let toastLabel = '';

    switch (type) {
      case 'questions':
        addEventTracking('Share', 'Question');
        toastLabel = t('modalShare.question_shared');
        break;
      case 'surveySteps':
        addEventTracking('Share', 'Step');
        toastLabel = t('modalShare.step_shared');
        break;
      case 'surveyVersions':
        addEventTracking('Share', 'Survey');
        toastLabel = t('modalShare.survey_shared');
        break;
      case 'surveys':
        break;
      case 'entities':
        addEventTracking('Share', 'Entity');
        toastLabel = t('modalShare.entity_shared');
        break;
      case 'actions':
        addEventTracking('Share', 'Action');
        toastLabel = t('modalShare.action_shared');
        break;
      default:
        break;
    }
    onClose();

    onClose();

    const sharedItemInformations = {
      targetSurveyId: targetSurveyId,
      relatedProductId: relatedProductId,
      currentStep: currentStep,
      currentVersion: currentVersion,
      currentSurvey: currentSurvey,
      currentEntity: currentEntity,
      itemId: id,
    };

    dispatch(
      shareItem({
        accountId: activeAccount._id,
        hostId: host._id,
        comment: commentInput,
        itemType: type,
        sharedItemInformations: sharedItemInformations,
        usersChecked: usersChecked,
        emailInputList: emailInputList,
        entity: currentEntity._id,
      })
    );

    toast.info(toastLabel);
    setUsersChecked([]);
    setEmailInputList([]);
    return;
  };

  const isItemShared = (user, itemType, shareId) => {
    for (let shareType in user.sharedItems) {
      if (shareType === '_id') continue;
      if (user.sharedItems[shareType].find(shareItem => shareItem?.shareId?.includes(shareId) && shareItem.entityId === currentEntity?._id)) {
        return true;
      }
    }
    return false;
  };

  const isFullyShared = (user, itemType) => {
    return user?.sharedItems?.[itemType]?.find(
      shareItem => shareItem?.[getItemKey(itemType)] === 'all' && shareItem?.entityId === currentEntity?._id
    );
  };

  const isAlreadyShared = (user, itemType, shareId) => {
    if (user._id === host._id) return true;
    if (isItemShared(user, itemType, shareId)) return true;
    if (itemType === 'actions') {
      return isActionResponsible(user, shareId, currentEntity._id);
    }

    if (!isFullyShared(user, itemType)) return false;
    switch (itemType) {
      case 'questions':
        return isAlreadyShared(user, 'surveySteps', currentStep._id);
      case 'surveySteps':
        return isAlreadyShared(user, 'surveyVersions', currentVersion._id);
      case 'surveyVersions':
        return isAlreadyShared(user, 'surveys', currentSurvey._id);
      case 'surveys':
        return isAlreadyShared(user, 'entities', currentEntity._id);
      case 'actions':
        return isAlreadyShared(user, 'entities', currentEntity._id) || isFullyShared(user, 'actions');
      case 'entities':
        return false;
    }
  };

  const isActionResponsible = (user, shareId, entityId) => {
    if (shareId === 'all') {
      let entity = activeAccount.entities.find(entity => entity._id === entityId);
      return entity.actionPlan.actions.every(action => action.responsible.some(responsible => responsible.id === user._id));
    }
    let action = getActionByShareId(activeAccount, shareId);
    return action?.responsible.some(responsible => responsible.id === user._id);
  };

  const getItemKey = itemType => {
    switch (itemType) {
      case 'questions':
        return 'questionId';
      case 'surveySteps':
        return 'surveyStepId';
      case 'surveyVersions':
        return 'surveyVersionId';
      case 'surveys':
        return 'surveyId';
      case 'entities':
        return 'entityId';
      case 'actions':
        return 'actionId';
      default:
        return '';
    }
  };

  const getShareTitle = shareTitle => {
    switch (shareTitle) {
      case 'entities':
        return `${label}`;
      case 'surveyVersions':
        return `${label}`;
      case 'surveySteps':
        return `${label}`;
      case 'questions':
        return `Une question`;
      case 'actions':
        if (label) {
          return `${t('modalShare.theAction')} : ${label}`;
        }
        return `${t('modalShare.shareAllActionPlan_modal_title')}`;
    }
  };

  return (
    <>
      {show && (
        <Modal show={show} title={t('modalShare.share_modal_title', { shareType: getShareTitle(type) })} onClose={onClose} size={{ width: '50%' }}>
          <ModalShareListUser
            type={type}
            shareID={id}
            isAlreadyShared={isAlreadyShared}
            onClose={onClose}
            handleShare={handleShare}
            emailInputList={emailInputList}
            setEmailInputList={setEmailInputList}
            commentInput={commentInput}
            setCommentInput={setCommentInput}
            usersChecked={usersChecked}
            setUsersChecked={setUsersChecked}
          />
        </Modal>
      )}
    </>
  );
};

export default ModalShare;
